import { ReactSVG } from 'react-svg';
import { h, Fragment, Component, render } from 'preact';
import PencilIcon from '../../../images/pencil-icon.svg';

const EditIcon = ({ width = '20px', height = '20px' }) => {
  return (
    <ReactSVG
      src={PencilIcon}
      beforeInjection={(svg) => {
        svg.setAttribute('width', width);
        svg.setAttribute('height', height);
      }}
    />
  );
};

export default EditIcon;
