import { h, Fragment, Component, render } from 'preact';
import { createContext } from 'preact';
import { useContext, useEffect, useMemo, useReducer } from 'preact/hooks';
import { useLocalStorageState } from '../customHooks/useLocalStorageState';
import { ITEMS } from '../../helpers/items-constant';

const YoutubeContext = createContext();

export const NOTHING = 'NOTHING';
export const PLAYLIST = 'playlist';
export const VIDEO = 'video';

export const playType = {
  video: VIDEO,
  playlist: PLAYLIST,
  nothing: NOTHING
};

const PLAY_YOUTUBE_PLAYLIST = 'play youtube playlist';
const PLAY_YOUTUBE_VIDEO = 'play youtube video';
const ADD_FAVORITE_YOUTUBE_ITEM = 'add favorite youtube item';
const REMOVE_FAVORITE_YOUTUBE_ITEM = 'remove favorite youtube item';

function youtubeReducer(state, action) {
  switch (action.type) {
    case PLAY_YOUTUBE_PLAYLIST:
      return {
        ...state,
        playType: playType.playlist,
        playUrl: action.payload.url,
        thumbnail: action.payload.thumbnail,
        title: action.payload.title
      };
    case PLAY_YOUTUBE_VIDEO:
      return {
        ...state,
        playType: playType.video,
        playUrl: action.payload.url,
        thumbnail: action.payload.thumbnail,
        title: action.payload.title
      };
    case ADD_FAVORITE_YOUTUBE_ITEM:
      /** have to check for state.favorites to make it backward compatible
       * with browsers that didn't have the favorites state
       */
      return {
        ...state,
        favorites: state.favorites
          ? [
              ...state.favorites,
              {
                playType: action.payload.playType,
                playUrl: action.payload.playUrl,
                thumbnail: action.payload.thumbnail,
                title: action.payload.title
              }
            ]
          : [
              {
                playType: action.payload.playType,
                playUrl: action.payload.playUrl,
                thumbnail: action.payload.thumbnail,
                title: action.payload.title
              }
            ]
      };
    case REMOVE_FAVORITE_YOUTUBE_ITEM:
      return {
        ...state,
        favorites: state.favorites.filter((fav) => fav.playUrl !== action.payload.playUrl)
      };
    default:
      throw new Error(`${action.type} does not exist in youtube reducer`);
  }
}

export function YoutubeContextProvider(props) {
  const [youtubeLocalStorage, setYoutubeLocalStorage] = useLocalStorageState(ITEMS.youtube, {
    playType: playType.nothing,
    playUrl: NOTHING,
    favorites: []
  });

  const [state, dispatch] = useReducer(youtubeReducer, youtubeLocalStorage);

  useEffect(() => {
    setYoutubeLocalStorage(state);
  }, [state, state?.playType, state?.playUrl]);

  const value = useMemo(() => [state, dispatch], [state, dispatch]);

  return <YoutubeContext.Provider value={value} {...props} />;
}

export function useYoutubeContext() {
  const context = useContext(YoutubeContext);
  if (context === undefined) {
    throw new Error('useYoutubeContext must be within a Youtube Context Provider');
  }

  return context;
}

export const addYoutubeFavorite = (dispatch, playUrl, playType, thumbnail, title) => {
  dispatch({
    type: ADD_FAVORITE_YOUTUBE_ITEM,
    payload: {
      playType,
      playUrl,
      thumbnail,
      title
    }
  });
};

export const removeYoutubeFavorite = (dispatch, playUrl) => {
  dispatch({
    type: REMOVE_FAVORITE_YOUTUBE_ITEM,
    payload: {
      playUrl
    }
  });
};

export const playYoutubeVideo = (dispatch, url, thumbnail, title) => {
  dispatch({
    type: PLAY_YOUTUBE_VIDEO,
    payload: {
      url,
      thumbnail,
      title
    }
  });
};

export const playYoutubePlayList = (dispatch, url, thumbnail, title) => {
  dispatch({
    type: PLAY_YOUTUBE_PLAYLIST,
    payload: {
      url,
      thumbnail,
      title
    }
  });
};
