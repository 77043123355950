import { ReactSVG } from 'react-svg';
import { h, Fragment, Component, render } from 'preact';
import AlarmClock from '../../../images/alarm-clock-icon.svg';

const AlarmClockIcon = ({ width = '20px', height = '20px' }) => {
  return (
    <ReactSVG
      src={AlarmClock}
      beforeInjection={(svg) => {
        svg.setAttribute('width', width);
        svg.setAttribute('height', height);
      }}
    />
  );
};

export default AlarmClockIcon;
