import { h, Fragment, Component, render } from 'preact';
import { Dialog, Transition } from '@headlessui/react';
import { useRef } from 'preact/hooks';

const Modal = ({ isOpen, closeModal, title, description, children }) => {
  let bodyRef = useRef(null);
  return (
    <Dialog
      initialFocus={bodyRef}
      className="fixed z-10 inset-0 overflow-y-auto"
      open={isOpen}
      onClose={() => closeModal()}
    >
      <div className="flex items-center justify-center min-h-screen max-w-full">
        <Dialog.Overlay className="fixed top-0 left-0 bottom-0 right-3 bg-black opacity-30 z-0" />
        <div
          ref={bodyRef}
          className="main-background
         bg-opacity-80 rounded  z-10 p-5 flow-content"
        >
          <Dialog.Title className=" text-2xl uppercase">{title}</Dialog.Title>
          <Dialog.Description className=" text-2xl">{description}</Dialog.Description>
          {children}
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;
