import { h, Fragment, Component, render } from 'preact';
import { useContext, useEffect, useMemo, useReducer } from 'preact/hooks';
import { createContext } from 'preact';
import { ITEMS } from '../../helpers/items-constant';
import { useLocalStorageState } from '../customHooks/useLocalStorageState';

/*
Reminders State
{
    reminders: [
        {
            uuid: 123,
            type: 'Sticky Note',
            reminderDate: 2022-02-02 11:30 AM
        }
    ],
    remindersVal : {
        uuid: 2022-02-02 11:30 AM
    }
}
*/

const RemindersContext = createContext({
  reminders: [],
  remindersVal: {}
});

const CREATE_NEW_REMINDER = 'create new reminder';
const DELETE_REMINDER = 'delete reminder';
const REMOVE_ALL_REMINDERS = 'remove all reminders';

function removeReminderVal(reminderVals, uuid) {
  const newReminderVals = { ...reminderVals };
  if (newReminderVals[uuid]) {
    delete newReminderVals[uuid];
  }
  return newReminderVals;
}

function remindersReducer(state, action) {
  switch (action.type) {
    case CREATE_NEW_REMINDER:
      return {
        remindersVal: {
          ...state.remindersVal,
          [action.payload.uuid]: action.payload.reminderDate
        },
        reminders: [
          ...state.reminders,
          {
            uuid: action.payload.uuid,
            type: action.payload.type,
            reminderDate: action.payload.reminderDate
          }
        ]
      };
    case DELETE_REMINDER:
      return {
        reminders: state.reminders.filter((reminder) => reminder.uuid !== action.payload.uuid),
        remindersVal: removeReminderVal(state.remindersVal, action.payload.uuid)
      };
    case REMOVE_ALL_REMINDERS:
      return {
        reminders: [],
        remindersVal: {}
      };
    default:
      throw new Error(`${action.type} does not exist in reminder reducer`);
  }
}

export function handleCreateReminderAction(dispatch, uuid, reminderDate) {
  dispatch({
    type: CREATE_NEW_REMINDER,
    payload: {
      uuid,
      reminderDate,
      type: ITEMS.stickyNotes
    }
  });
}

export function handleDeleteReminderAction(dispatch, uuid) {
  dispatch({
    type: DELETE_REMINDER,
    payload: {
      uuid
    }
  });
}

export function handleRemoveAllRemindersAction(dispatch) {
  dispatch({
    type: REMOVE_ALL_REMINDERS
  });
}

export function RemindersContextProvider(props) {
  const [remindersLocalStorage, setRemindersLocalStorage] = useLocalStorageState(ITEMS.reminders, {
    reminders: [],
    remindersVal: {}
  });
  const [state, dispatch] = useReducer(remindersReducer, remindersLocalStorage);

  useEffect(() => {
    setRemindersLocalStorage(state);
  }, [state, state?.reminders, state?.remindersVal]);

  const value = useMemo(() => [state, dispatch], [state, dispatch]);

  return <RemindersContext.Provider value={value} {...props} />;
}

export function useRemindersContext() {
  const context = useContext(RemindersContext);
  if (context === undefined) {
    throw new Error('useRemindersContext must be within a reminders context provider');
  }

  return context;
}
