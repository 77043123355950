import { h, render } from 'preact';
import { useEffect } from 'preact/hooks';
import { Wallpaper } from '../../../services/Wallpaper';
import { useAsync } from '../../customHooks/useAsync';
import PaintBrushIcon from '../Icons/PaintBrushIcon';
import { DEFAULT_WALLPAPER } from '../../../helpers/constant';

const WallpaperButton = ({ setWallpaper }) => {
  const { data, status, error, run } = useAsync({
    status: 'idle'
  });

  function changeWallpaper() {
    run(Wallpaper.fetchWallpaper());
  }

  useEffect(() => {
    if (status === 'resolved') {
      let image_link = data?.data?.urls?.full || '';
      let author_name = data?.data?.user?.name || '';
      let username = data?.data?.user?.username || '';
      setWallpaper({
        image_link,
        author_name,
        username
      });
    }
  }, [status]);

  return (
    <button class="btn-background btn-gradient" onClick={changeWallpaper}>
      Change background
    </button>

  );
};

export default WallpaperButton;
