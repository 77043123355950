/**
 *
 * We have different items and state they can be rendered in,
 * such as sticky notes, reminders and trash.
 *
 * There could also be other items that might be added in the future,
 * such as calendar event and what note.
 * This is where we define a standard naming and other constants.
 *
 */

export const STICKY_NOTE = 'STICKY_NOTE';
export const TRASH_BOX = 'TRASH_BOX';
export const ACCESSIBLE = 'ACCESSIBLE';
export const REMINDERS = 'REMINDERS';
export const YOUTUBE_PLAY_OPTIONS = 'YOUTUBE_PLAY_OPTIONS';
export const SOUNDCLOUD_PLAY_OPTIONS = 'SOUNDCLOUD_PLAY_OPTIONS';
export const TRASH_BOX_OPEN_STATE = 'TRASH_BOX_OPEN_STATE';
export const CALENDAR = 'CALENDAR';

export const UNDERLINE = '_';

export const ITEMS = {
  stickyNotes: STICKY_NOTE,
  accessibleStickyNotes: ACCESSIBLE + UNDERLINE + STICKY_NOTE,
  trashBox: TRASH_BOX,
  reminders: REMINDERS,
  youtube: YOUTUBE_PLAY_OPTIONS,
  soundcloud: SOUNDCLOUD_PLAY_OPTIONS,
  calendar: CALENDAR
};

export const COLORS = {
  default: 'default-color',
  colorOne: 'color-one',
  colorTwo: 'color-two',
  colorThree: 'color-three',
  colorFour: 'color-four',
  colorFive: 'color-five',
  colorSix: 'color-six',
  colorSeven: 'color-seven'
};
