import { h, Component, render, Fragment } from 'preact';
import { useCalendarDates } from '../../customHooks/useCalendarDates';
import dayjs from 'dayjs';
import './calendar.css';
import LeftArrowIcon from '../Icons/LeftIcon';
import RightArrowIcon from '../Icons/RightIcon';
import useModal from '../../customHooks/useModal';
import Modal from '../Modal/Modal';
import { useEffect, useRef, useState } from 'preact/hooks';
import ReactDatePicker from 'react-datepicker';
import { useCalendar } from '../../customHooks/useCalendar';
import { useRemindersContext } from '../../context/reminders';
import { filterTime } from '../../../utils/reminder';
import FullTrashIcon from '../Icons/FullTrashIcon';
import CloseIcon from "../Icons/CloseIcon.jsx";

const extractEventDate = (events, uuid) => events[uuid].eventDate;

const getDateColor = (selectedDate, day) => {
  if (day.getMonth() === selectedDate.clone().toDate().getMonth()) {
    if (dayjs().isSame(day, 'date')) return '#E43F5A';
    return '#1B1B2F';
  }

  return '#DAE1E7';
};

const createReminderDate = (selectedReminder, calendarDay) => {
  const reminderHours = selectedReminder.getHours();
  const reminderMinutes = selectedReminder.getMinutes();

  const reminderDate = dayjs(calendarDay).clone().hour(reminderHours).minute(reminderMinutes);
  return reminderDate.toDate();
};

const CreateEventForm = ({ day, closeModal }) => {
  const [reminderDate, setReminderDate] = useState(null);
  const [isStickyNote, setIsStickyNote] = useState(false);

  const createEventFormInputRef = useRef();

  useEffect(() => {
    createEventFormInputRef.current.focus();
  }, []);

  const { createNewEvent } = useCalendar();

  const handleSubmit = (formData) => {
    const note = formData.get('note');
    if (!note || note.trim() === '') {
      return;
    }
    const reminder = reminderDate ? createReminderDate(reminderDate, day) : null;

    createNewEvent({
      note,
      reminder,
      isStickyNote,
      eventDate: day
    });
    closeModal();
  };

  return (
    <div className="text-black">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(new FormData(e.currentTarget));
        }}
        className="flex flex-col gap-2"
      >
        <label for="note">Event Note</label>
        <textarea ref={createEventFormInputRef} name="note" rows={4} className="p-1 border-style-one"></textarea>
        <label for="reminder" className="rounded-sm">
          Reminder
        </label>
        <ReactDatePicker
          selected={reminderDate}
          onChange={(date) => setReminderDate(date)}
          showTimeSelect
          showTimeSelectOnly
          autoComplete="off"
          timeIntervals={1}
          timeCaption="Time"
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          dateFormat="h:mm aa"
          className="w-full px-2 py-1 rounded-sm border-style-one"
          name="reminder"
        />
        <div className="flex gap-2">
          <label for="isStickyNote">Create sticky note</label>
          <input
            type="checkbox"
            name="isStickyNote"
            className="border-style-one"
            checked={isStickyNote}
            onChange={(e) => setIsStickyNote(!isStickyNote)}
          />
        </div>
        <div className="flex gap-3 mt-2">
          <button type="submit">Save</button>
          <button onClick={closeModal}> Cancel</button>
        </div>
      </form>
    </div>
  );
};

const CalendarPlusButton = ({ day }) => {
  const [createEventModalOpen, openCreateEventModal, closeCreateEventModal] = useModal();

  return (
    <>
      <span
        className="border-2 absolute left-2 
        top-1 px-2 rounded-sm grid place-items-center calendar-day-add-btn"
        onClick={openCreateEventModal}
      >
        +
      </span>
      <Modal isOpen={createEventModalOpen} closeModal={closeCreateEventModal}>
        <CreateEventForm day={day} closeModal={closeCreateEventModal} />
      </Modal>
    </>
  );
};

const EditEventForm = ({ event, closeModal }) => {
  const [remindersState] = useRemindersContext();
  const eventReminderDate = remindersState?.remindersVal?.[event.uuid] ?? null;

  const [reminderDate, setReminderDate] = useState(eventReminderDate);
  const [isStickyNote, setIsStickyNote] = useState(event.isStickyNote);

  const { deleteEvent, updateEvent } = useCalendar();

  const handleSubmit = (formData) => {
    const note = formData.get('note');
    if (!note || note.trim() === '') {
      return;
    }
    const reminder = reminderDate ? new Date(reminderDate) : null;

    updateEvent({
      ...event,
      note,
      reminder,
      isStickyNote
    });
    closeModal();
  };

  const clearEventReminder = () => {
    setReminderDate(null);
  };

  return (
    <div className="text-black">
      <div
        onClick={() => deleteEvent(event.uuid)}
        className="mb-2 cursor-pointer black-svg  flex gap-2 w-full justify-end"
      >
        <FullTrashIcon />
        <span>Delete Note</span>
      </div>
      <hr />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(new FormData(e.currentTarget));
        }}
        className="flex flex-col gap-2 py-2"
      >
        <label for="note">Event Note</label>
        <textarea name="note" value={event.note} rows={4} className="p-1 border-style-one"></textarea>
        <div className="flex gap-2 ">
          <label for="reminder" className="rounded-sm">
            Reminder
          </label>
          {reminderDate && (
            <span className="cursor-pointer black-svg" onClick={clearEventReminder}>
              <FullTrashIcon />
            </span>
          )}
        </div>

        <ReactDatePicker
          className="w-full px-2 py-1 rounded-sm border-style-one"
          selected={reminderDate ? new Date(reminderDate) : null}
          showTimeSelect
          onChange={(date) => setReminderDate(date)}
          dateFormat="MMMM d, yyyy h:mm aa"
          minDate={new Date()}
          placeholderText="Pick reminder date and time"
          timeIntervals={2}
          filterTime={filterTime}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
        <div className="flex gap-2">
          <label for="isStickyNote">Is sticky note ?</label>
          <input
            type="checkbox"
            name="isStickyNote"
            className="border-style-one"
            checked={isStickyNote}
            onChange={(e) => setIsStickyNote(!isStickyNote)}
          />
        </div>
        <div className="flex gap-3 mt-2">
          <button type="submit">Save</button>
          <button onClick={closeModal}> Cancel</button>
        </div>
      </form>
    </div>
  );
};

const CalendarDayEvent = ({ event }) => {
  const [editEventModalOpen, openEditEventModal, closeEditEventModal] = useModal();

  return (
    <>
      <span
        onClick={openEditEventModal}
        className="p-1 border-2 rounded-lg shadow-sm cursor-pointer event-prev-tab"
        style={{ maxWidth: '110px' }}
      >
        {event.note}
      </span>
      <Modal isOpen={editEventModalOpen} closeModal={closeEditEventModal}>
        <EditEventForm event={event} closeModal={closeEditEventModal} />
      </Modal>
    </>
  );
};

const CalendarDay = ({ day, selectedDate }) => {
  const { accessible, events } = useCalendar();

  const calendarDayEvents = accessible.filter((uuid) => dayjs(extractEventDate(events, uuid)).isSame(day, 'date'));
  return (
    <div
      className="flex relative items-center justify-center calendar-day"
      style={{
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderRightWidth: '1px',
        minHeight: '110px'
      }}
    >
      <CalendarPlusButton day={day} />
      <div className="absolute right-0 top-0" style={{ color: getDateColor(selectedDate, day) }}>
        {day.getDate()}
      </div>
      <div className="flex flex-col gap-1 mt-8">
        {calendarDayEvents.map((uuid) => (
          <CalendarDayEvent event={events[uuid]} />
        ))}
      </div>
    </div>
  );
};

const Calendar = ({closeCalendar}) => {
  const { selectedDate, generateDatesOfTheMonth, changeToNextMonth, changeToPreviousMonth } = useCalendarDates();

  return (
    <div className="bg-white text-black p-2 rounded-md relative">
      <div className="flex items-center gap-2">
        <button onClick={changeToPreviousMonth}>
          <LeftArrowIcon />
        </button>
        <h3>{selectedDate.clone().format('MMM YYYY')}</h3>
        <button onClick={changeToNextMonth}>
          <RightArrowIcon />
        </button>
      </div>

      <div className="weeks-container">
        {generateDatesOfTheMonth[0].map((day, index) => {
          return (
            <div key={`weekday-${index}`} className="flex items-center justify-center" style={{ color: '#9BA4B4' }}>
              {dayjs(day).format('dd')}
            </div>
          );
        })}
      </div>

      <div>
        {generateDatesOfTheMonth.map((week, weekIndex) => (
          <div
            key={`week-${weekIndex}`}
            className="weeks-container"
            style={{ borderLeftWidth: '1px', borderRightWidth: '1px' }}
          >
            {week.map((day, dayIndex) => {
              return <CalendarDay selectedDate={selectedDate} day={day} key={`day-${dayIndex}`} />;
            })}
          </div>
        ))}
      </div>
      <button onClick={closeCalendar} className="absolute right-2 top-0">
        <CloseIcon />
      </button>
    </div>
  );
};

export default Calendar;
