import { addCalendarEventToTrash, addStickyNoteToTrash, useTrashBoxContext } from '../context/trashBox';
import {
  handleCreateNewStickyNoteAction,
  handleDeleteStickyNoteAction,
  handleUpdateStickyNoteAction,
  useStickyNoteBoxContext
} from '../context/stickyNoteBox';
import { handleDeleteCalendarEventAction, handleUpdateCalendarEvent, useCalendarContext } from '../context/calendar';

export const fetchStickyNoteData = (currentStickyNotes) => {
  return (uuid) => currentStickyNotes[uuid];
};

export const useStickyNotes = () => {
  const [, dispatchTrashBox] = useTrashBoxContext();
  const [stickyNoteBox, dispatchStickyNoteBox] = useStickyNoteBoxContext();
  const [calendarVals, dispatchCalendar] = useCalendarContext();

  const accessibleStickyNotes = stickyNoteBox.accessible;
  const currentStickyNotes = stickyNoteBox.stickyNotes;

  const createNewStickyNote = (stickyNote) => {
    handleCreateNewStickyNoteAction(dispatchStickyNoteBox, stickyNote);
  };

  const updateStickyNote = (updatedNoteData) => {
    handleUpdateStickyNoteAction(dispatchStickyNoteBox, updatedNoteData);

    // SYNCING WITH CALENDAR EVENT IF IT EXISTS
    const { uuid, note } = updatedNoteData;
    if (calendarVals.events[uuid]) {
      handleUpdateCalendarEvent(dispatchCalendar, {
        ...calendarVals.events[uuid],
        note
      });
    }
  };

  const deleteStickyNote = (uuid) => {
    handleDeleteStickyNoteAction(dispatchStickyNoteBox, uuid);
    addStickyNoteToTrash(dispatchTrashBox, uuid);
    // TODO: sync with calendar event if it exists

    // SYNCING WITH CALENDAR EVENT IF IT EXISTS
    if (calendarVals.events[uuid]) {
      handleDeleteCalendarEventAction(dispatchCalendar, uuid);
      addCalendarEventToTrash(dispatchTrashBox, uuid);
    }
  };

  const stickyNoteDataFetcher = fetchStickyNoteData(currentStickyNotes);

  return {
    accessibleStickyNotes,
    createNewStickyNote,
    updateStickyNote,
    stickyNoteDataFetcher,
    deleteStickyNote
  };
};
