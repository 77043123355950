import { h, component, render } from "preact";

const SettingIcon = ({ ...style }) => (
	<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 24 24" style="enable-background:new 0 0 512 512" {...style}>
		<g>
			<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="2.5" x2="21.6" y1="12" y2="12"><stop stop-opacity="1" stop-color="#338fc9" offset="0">
			</stop>
				<stop stop-opacity="1" stop-color="#232323" offset="1">
				</stop>
			</linearGradient>
			<path d="m17.3 11c2.3 0 4.3-1.9 4.3-4.3s-1.9-4.3-4.3-4.3-4.3 2-4.3 4.4 1.9 4.2 4.3 4.2zm0-6.5c1.2 0 2.3 1 2.3 2.3s-1.1 2.2-2.3 2.2-2.3-1-2.3-2.2 1-2.3 2.3-2.3zm0 8.5c-2.3 0-4.3 1.9-4.3 4.3s1.9 4.3 4.3 4.3 4.3-1.9 4.3-4.3-2-4.3-4.3-4.3zm0 6.5c-1.2 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3-1.1 2.3-2.3 2.3zm-10.5-17c-2.3 0-4.3 1.9-4.3 4.3s1.9 4.2 4.3 4.2 4.2-1.9 4.2-4.2-1.9-4.3-4.2-4.3zm0 6.5c-1.3 0-2.3-1-2.3-2.2s1-2.3 2.3-2.3 2.2 1 2.2 2.3-1 2.2-2.2 2.2zm0 4c-2.3 0-4.3 1.9-4.3 4.3s1.9 4.3 4.3 4.3 4.3-1.9 4.3-4.3-2-4.3-4.3-4.3zm0 6.5c-1.2 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.2 1.1 2.2 2.4-1 2.2-2.2 2.2z" fill="url(#SVGID_1_)" data-original="url(#SVGID_1_)">
			</path>
		</g>
	</svg>
);

export default SettingIcon;
