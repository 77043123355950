import { h, Fragment, Component, render } from 'preact';
import { useLocalStorageState } from '../../customHooks/useLocalStorageState';
import { SELECTED_OPTION, searchOption } from '../../../helpers/constant';
import { useState, useRef, useEffect } from 'preact/hooks';

const Search = ({ selectedSearchEngine, setSelectedSearchEngine, searchList }) => {
  const [selectedOption, setSelectedOption] = useLocalStorageState(SELECTED_OPTION, 'link');
  const [showSearchEngine, setShowSearchEngine] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  const handleQuerySearch = (e) => {
    e.preventDefault();
    let query = e.srcElement[0].value;
    if (query) {
      let redirectLink = selectedSearchEngine[selectedOption] + query;
      window.open(redirectLink);
    }
  };

  return (
    <div className="flex flex-col gap-4 w-full">
      <form className="flex flex-col w-full gap-2" target="_blank" action={selectedSearchEngine[selectedOption]}>
        <input type="hidden" name="id" value="51a9cb020b2c10f493b8614fce2f49339104572c"></input>
        <div>
          <ul className="flex main-background p-3 rounded-full text-xl justify-items-start py-2 pointer-events-auto" style={{maxWidth: 'fit-content'}}>
            {searchOption.map((option) => (
              <li
                onClick={() => setSelectedOption(option.value)}
                className={`search-option ${option.value === selectedOption && 'search-option--active '}`}
              >
                {option.name}
              </li>
            ))}
          </ul>
        </div>
        <div class="search pointer-events-auto">
          <input
            id="query"
            name="q"
            required
            minlength="1"
            ref={searchRef}
            className="main-background rounded-l-full h-12 outline-none pl-4 text-lg text-primary-dark2 focused-search black-text border-remove"
          />
          <button
            type="submit"
            className="center-glass h-12 w-14 pl-2 pr-2 py-1 main-background rounded-r-full cursor-pointer border-l-primary-dark border-primary-dark transition-all border-remove little-margin-left"
          >
            <img src={selectedSearchEngine.icon} alt="Search Engine Logo" className="resize max-w-full max-h-full super-color" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default Search;
