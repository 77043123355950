import { ReactSVG } from 'react-svg';
import { h, Fragment, Component, render } from 'preact';
import YoutubeIco from '../../../images/youtube.svg';

const YoutubeIcon = ({ width = '20px', height = '20px' }) => {
  return (
    <ReactSVG
      src={YoutubeIco}
      beforeInjection={(svg) => {
        svg.setAttribute('width', width);
        svg.setAttribute('height', height);
      }}
    />
  );
};

export default YoutubeIcon;
