import { h, Fragment, Component, render } from 'preact';
import { createContext } from 'preact';
import { useContext, useEffect, useMemo, useReducer } from 'preact/hooks';
import { v4 as uuidv4 } from 'uuid';
import { ITEMS } from '../../helpers/items-constant';
import { useLocalStorageState } from '../customHooks/useLocalStorageState';

const CalendarContext = createContext([]);

const CREATE_CALENDAR_EVENT = 'create new calendar event';
const UPDATE_CALENDAR_EVENT = 'update calendar event';
const DELETE_CALENDAR_EVENT = 'delete calendar event';
const PERMANENTLY_DELETE_CALENDAR_EVENT = 'permanently delete calendar event';
const RESTORE_CALENDAR_EVENT = 'restore calendar event';

function createCalendarEvent({ note, eventDate, isStickyNote, uuid }) {
  return {
    uuid: uuid ? uuid : uuidv4(),
    note,
    eventDate,
    isStickyNote
  };
}

function updateCalendarEvent(calendarEvents, { uuid, note, isStickyNote, reminder }) {
  const newCalendarEvents = { ...calendarEvents };
  newCalendarEvents[uuid] = {
    ...newCalendarEvents[uuid],
    note,
    isStickyNote,
    eventDate: reminder ? reminder : newCalendarEvents[uuid].eventDate
  };

  return newCalendarEvents;
}

function removeEventPermanently(events, uuid) {
  const newEvents = { ...events };
  if (newEvents[uuid]) {
    delete newEvents[uuid];
  }

  return newEvents;
}

/*
    Calendar Event Note State

    {
        accessible: [uuid1, uuid2]
        events: {
            uuid1: {
                uuid: xxxx
                note: xxxx
                eventDate: xxxx
                isStickyNote: xxxx
            }
        }
    }
*/
function calendarReducer(state, action) {
  switch (action.type) {
    case CREATE_CALENDAR_EVENT:
      return {
        ...state,
        accessible: [...state.accessible, action.payload.event.uuid],
        events: {
          ...state.events,
          [action.payload.event.uuid]: {
            ...action.payload.event
          }
        }
      };
    case UPDATE_CALENDAR_EVENT:
      return {
        ...state,
        events: updateCalendarEvent(state.events, action.payload.event)
      };
    case DELETE_CALENDAR_EVENT:
      return {
        ...state,
        accessible: state.accessible.filter((uuid) => uuid !== action.payload.uuid)
      };
    case PERMANENTLY_DELETE_CALENDAR_EVENT:
      return {
        ...state,
        events: {
          ...removeEventPermanently(state.events, action.payload.uuid)
        }
      };
    case RESTORE_CALENDAR_EVENT:
      return {
        ...state,
        accessible: [...state.accessible, action.payload.uuid]
      };
    default:
      throw new Error(`${action.type} does not exist in calendar reducer`);
  }
}

export function CalendarContextProvider(props) {
  const [calendarLocalStorage, setCalendarLocalStorage] = useLocalStorageState(ITEMS.calendar, {
    accessible: [],
    events: {}
  });

  const [state, dispatch] = useReducer(calendarReducer, calendarLocalStorage);

  useEffect(() => {
    setCalendarLocalStorage(state);
  }, [state, state?.events, state?.accessible]);

  const value = useMemo(() => [state, dispatch], [state, dispatch]);

  return <CalendarContext.Provider value={value} {...props} />;
}

export function useCalendarContext() {
  const context = useContext(CalendarContext);
  if (context === undefined) {
    throw new Error('useCalendarContext must be within a Calendar Context Provider');
  }

  return context;
}

export function handleCreateNewCalendarEvent(dispatch, event) {
  const newCalendarEvent = createCalendarEvent(event);

  dispatch({
    type: CREATE_CALENDAR_EVENT,
    payload: {
      event: {
        ...newCalendarEvent
      }
    }
  });
  return newCalendarEvent;
}

export function handleUpdateCalendarEvent(dispatch, event) {
  dispatch({
    type: UPDATE_CALENDAR_EVENT,
    payload: {
      event
    }
  });
}

export function handleDeleteCalendarEventAction(dispatch, uuid) {
  dispatch({
    type: DELETE_CALENDAR_EVENT,
    payload: {
      uuid
    }
  });
}

export function handlePermanentlyDeleteCalendarEventAction(dispatch, uuid) {
  dispatch({
    type: PERMANENTLY_DELETE_CALENDAR_EVENT,
    payload: {
      uuid
    }
  });
}

export function handleRestoreCalendarevent(dispatch, uuid) {
  dispatch({
    type: RESTORE_CALENDAR_EVENT,
    payload: {
      uuid
    }
  });
}
