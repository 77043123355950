import { ReactSVG } from 'react-svg';
import { h, Fragment, Component, render } from 'preact';
import PaintBrush from '../../../images/paint-brush.png';

const PaintBrushIcon = ({ width = '20px', height = '20px' }) => {
  return (
    <div>
      <img src={PaintBrush} className="w-full rounded-lg" />
    </div>
  );
};

export default PaintBrushIcon;
