import { ReactSVG } from 'react-svg';
import { h, Fragment, Component, render } from 'preact';
import SearchIco from '../../../images/search.svg';

const SearchIcon = ({ width = '20px', height = '20px' }) => {
  return (
    <ReactSVG
      src={SearchIco}
      beforeInjection={(svg) => {
        svg.setAttribute('width', width);
        svg.setAttribute('height', height);
      }}
    />
  );
};

export default SearchIcon;
