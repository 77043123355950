import { h, Fragment, Component, render } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { SOUNDCLOUD_TRACK } from '../../context/soundcloud';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { SoundcloudAPI } from '../../../services/Soundcloud';
import {
  addSoundcloudFavorite,
  playSoundcloudTrack,
  removeSoundcloudFavorite,
  SoundcloudContextProvider,
  SOUNDCLOUD_NOTHING,
  useSoundcloudContext
} from '../../context/soundcloud';
import { useAsync } from '../../customHooks/useAsync';
import AddFavIcon from '../Icons/AddFavIcon';
import CloseIcon from '../Icons/CloseIcon';
import IsFavIcon, { IsFavIconAlt } from '../Icons/IsFavIcon';
import SearchIcon from '../Icons/SearchIcon';
import SoundcloudHeartIcon from '../Icons/SoundcloudHeart';
import SoundcloudIcon from '../Icons/SoundcloudIcon';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import './soundcloud.css';

const extractItemTitle = (soundcloudItem) => soundcloudItem?.title || '';
const extractItemUri = (soundcloudItem) => soundcloudItem?.uri;
const extractItemThumbnail = (soundcloudItem) => soundcloudItem?.artwork_url;

const UI_TYPE_SEARCH = 'UI_TYPE_SEARCH';
const UI_TYPE_FAV = 'UI_TYPE_FAV';

const SoundcloudResultUI = ({
  itemUri,
  thumbnail,
  title,
  playItem,
  addToFavorite,
  removeFromFavorite,
  type = UI_TYPE_SEARCH,
  isFav = true
}) => (
  <div
    onClick={() => playItem(itemUri, thumbnail, title)}
    className="flex gap-1 max-w-md p-2 cursor-pointer"
    role="button"
  >
    {thumbnail && <img src={thumbnail} alt={`Image for ${title}`} style={{ width: '100px', height: '100px' }} />}
    {!thumbnail && <div style={{ width: '100px', height: '100px' }} />}

    <div className="w-full flex flex-col gap-1">
      {title ? <h3 className="w-full font-bold">{title}</h3> : <h3>Unknown Title</h3>}
    </div>

    {isFav && (
      <div className="tooltip cursor-pointer" style={{ alignSelf: 'start' }}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            removeFromFavorite(itemUri);
          }}
        >
          <SoundcloudHeartIcon width="20px" height="20px" />
        </div>
        <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-150px' }}>
          Remove from playlist
        </span>
      </div>
    )}
    {!isFav && type === UI_TYPE_SEARCH && (
      <div className="tooltip cursor-pointer" style={{ alignSelf: 'start' }}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            addToFavorite({
              playUrl: itemUri,
              playType: SOUNDCLOUD_TRACK,
              thumbnail,
              title
            });
          }}
        >
          <IsFavIcon width="20px" height="20px" />
        </div>
        <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-105px' }}>
          Add to playlist
        </span>
      </div>
    )}
  </div>
);

const SoundcloudSingleResult = ({ soundcloudItem, playItem, favorites, removeItem, addToFavorite }) => {
  const thumbnail = extractItemThumbnail(soundcloudItem);
  const itemUri = extractItemUri(soundcloudItem);
  const title = extractItemTitle(soundcloudItem);
  const isFav = favorites.includes(itemUri);

  if (!itemUri) {
    return <div className="main-background p-2">Corrupted Result</div>;
  }

  return (
    <SoundcloudResultUI
      isFav={isFav}
      removeFromFavorite={removeItem}
      itemUri={itemUri}
      thumbnail={thumbnail}
      title={title}
      playItem={playItem}
      addToFavorite={addToFavorite}
    />
  );
};

const SoundcloudSearchResult = ({
  searchStatus,
  searchError,
  searchData,
  handleNextPageChange,
  handlePrevPageChange,
  searchOffset
}) => {
  const [soundcloudState, soundcloudDispatch] = useSoundcloudContext();

  const favorites = (soundcloudState?.favorites ?? []).map((fav) => fav?.playUrl);

  const removeItem = (itemUri) => removeSoundcloudFavorite(soundcloudDispatch, itemUri);

  const addToFavorite = ({ playUrl, playType, thumbnail, title }) =>
    addSoundcloudFavorite(soundcloudDispatch, playUrl, playType, thumbnail, title);

  const playItem = (itemUrl, thumbnail, title) => {
    playSoundcloudTrack(soundcloudDispatch, itemUrl, thumbnail, title);
  };

  const soundcloudItems = searchData?.data?.collection;
  const hasNextPage = searchData?.data?.next_href;
  const hasPrevPage = searchOffset !== 0;

  if (searchStatus === 'pending') {
    return (
      <div className="main-background common-soundcloud-width p-2">
        <LoadingSpinner />
      </div>
    );
  }

  if (!soundcloudItems || soundcloudItems.length === 0) {
    return <div className="main-background p-4">No result found for the search.</div>;
  }

  return (
    <div className="main-background p-4 flex flex-col gap-2 max-w-xl max-h-96 overflow-auto common-soundcloud-width">
      {soundcloudItems.map((item) => (
        <SoundcloudSingleResult
          key={() => extractItemUri(item)}
          favorites={favorites}
          removeItem={removeItem}
          soundcloudItem={item}
          playItem={playItem}
          addToFavorite={addToFavorite}
        />
      ))}

      <div className="flex justify-between w-full">
        {hasPrevPage && <button onClick={handlePrevPageChange}>Prev Page</button>}
        {hasNextPage && <button onClick={handleNextPageChange}>Next Page</button>}
      </div>
    </div>
  );
};

const SoundcloudSearchForm = ({ handleSearch, searchTerm, setSearchTerm }) => {
  const inputRef = useRef();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const dataToSubmit = {
      q: searchTerm,
      offset: 0,
      limit: 10
    };
    handleSearch(dataToSubmit);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="main-background min-w-250px p-4 common-soundcloud-width">
      <form onSubmit={handleFormSubmit} className="flow-content">
        <div className="flex flex-col gap-2">
          <label for="soundcloud-search">Search for a song</label>
          <input
            ref={inputRef}
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="text-black p-2 border-style-one rounded-lg"
            name="soundcloud-search"
            placeholder="Type to search"
          />
        </div>
      </form>
    </div>
  );
};

const SoundcloudSearchContainer = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearch, setShowSearch] = useState(true);

  const [searchOffset, setSearchOffset] = useState(0);

  const { data, status, error, run } = useAsync({
    status: 'idle'
  });

  const searchSoundcloud = (params) => {
    run(SoundcloudAPI.searchTracks(params));
    setShowSearch(false);
  };

  const handleSearch = (dataToSubmit) => {
    searchSoundcloud({ ...dataToSubmit });
  };

  const handleNextPageChange = () => {
    handleSearch({ q: searchTerm, offset: searchOffset + 10, limit: 10 });
    setSearchOffset(searchOffset + 10);
  };

  const handlePrevPageChange = () => {
    if (searchOffset !== 0) {
      handleSearch({ q: searchTerm, offset: searchOffset - 10, limit: 10 });
      setSearchOffset(searchOffset - 10);
    }
  };

  return (
    <>
      {showSearch && (
        <SoundcloudSearchForm handleSearch={handleSearch} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      )}
      {!showSearch && (
        <div
          className="main-background w-full text-center cursor-pointer common-soundcloud-width"
          onClick={() => setShowSearch(true)}
        >
          Search Again
        </div>
      )}

      {!showSearch && (
        <SoundcloudSearchResult
          searchStatus={status}
          searchError={error}
          searchData={data}
          handleNextPageChange={handleNextPageChange}
          handlePrevPageChange={handlePrevPageChange}
          searchOffset={searchOffset}
        />
      )}
    </>
  );
};

const SoundcloudSearchOption = ({ isPopoverOpen, togglePopoverOpen }) => {
  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['left']}
      onClickOutside={() => null}
      padding={45}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'white'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <SoundcloudSearchContainer />
        </ArrowContainer>
      )}
    >
      <div className={`${!isPopoverOpen && 'tooltip'}`}>
        <button
          style={{ width: '25px', height: '25px', borderColor: `${isPopoverOpen ? '#338fc9' : 'grey'}` }}
          role="button"
          className={`${isPopoverOpen ? 'green-svg' : 'black-svg'} border-2 p-1 rounded-lg`}
          onClick={togglePopoverOpen}
        >
          <SearchIcon width="10px" height="10px" />
        </button>
        {!isPopoverOpen && (
          <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-65px' }}>
            Search
          </span>
        )}
      </div>
    </Popover>
  );
};

const NoSoundcloudFavorites = () => {
  return <div className="p-2">Your favorite music will show up here.</div>;
};

const SoundcloudFavoriteSingle = ({ fav, playItem, removeItem, type }) => {
  const { playUrl, thumbnail, title } = fav;
  return (
    <SoundcloudResultUI
      itemUri={playUrl}
      thumbnail={thumbnail}
      title={title}
      playItem={playItem}
      removeFromFavorite={() => removeItem(fav)}
      type={type}
    />
  );
};

const SoundcloudFavorites = ({ favorites, isPopoverOpen, togglePopoverOpen, removeFromFavorite, addToFavorite }) => {
  const favoritesContainerRef = useRef();

  const [, soundcloudDispatch] = useSoundcloudContext();
  const [removedItems, setRemovedItems] = useState([]);

  const playItem = (playUrl, thumbnail, title) => {
    playSoundcloudTrack(soundcloudDispatch, playUrl, thumbnail, title);
  };

  const removeItem = (item) => {
    if (removedItems.length === 0) {
      setRemovedItems([...removedItems, item]);
    }
    favoritesContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const undoRemoval = (item) => {
    setRemovedItems(removedItems.filter((i) => i.playUrl !== item.playUrl));
  };

  const confirmRemoval = (item) => {
    setRemovedItems(removedItems.filter((i) => i.playUrl !== item.playUrl));
    removeFromFavorite(item.playUrl);
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['left']}
      onClickOutside={() => null}
      padding={10}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'white'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div
            ref={favoritesContainerRef}
            className="main-background common-soundcloud-width overflow-y-auto"
            style={{ maxHeight: '350px' }}
          >
            {removedItems.length > 0 && (
              <div className="flex gap-1 mb-1 flex-col">
                {removedItems.map((item) => (
                  <div key={item.itemId} className="p-2 flex justify-between items-center">
                    <div className="flex gap-2 flex-col">
                      <span>Remove from playlist? </span>
                      <p className="font-bold" style={{ maxWidth: '300px' }}>
                        {item.title}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => confirmRemoval(item)}
                        className="items-center rounded-md p-2 text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                      <button
                        onClick={() => undoRemoval(item)}
                        className=" p-2 items-center rounded-md text-blue-500 hover:text-blue-700"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {favorites.length === 0 && <NoSoundcloudFavorites />}
            {favorites.length > 0 &&
              favorites.map((fav) => (
                <SoundcloudFavoriteSingle fav={fav} playItem={playItem} removeItem={removeItem} type={UI_TYPE_FAV} />
              ))}
          </div>
        </ArrowContainer>
      )}
    >
      <div className={`${!isPopoverOpen && 'tooltip'}`}>
        <button role="button" className=" p-1 rounded-lg text-white" onClick={togglePopoverOpen}>
          <AddFavIcon />
        </button>
        {!isPopoverOpen && (
          <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-90px' }}>
            Favorites list
          </span>
        )}
      </div>
    </Popover>
  );
};

const SoundcloudHeader = ({ toggleSoundcloud }) => {
  const [isFavoritePopoverOpen, setIsFavoritePopoverOpen] = useState(false);
  const [isSearchPopoverOpen, setIsSearchPopoverOpen] = useState(false);
  const [soundcloudState, soundcloudDispatch] = useSoundcloudContext();
  const favorites = soundcloudState?.favorites ?? [];
  const playUrl = soundcloudState?.playUrl ?? null;
  const playType = soundcloudState?.playType ?? null;
  const thumbnail = soundcloudState?.thumbnail ?? null;
  const title = soundcloudState?.title ?? null;

  const IS_SOUNDCLOUD_TRACK_SELECTED = Boolean(playUrl) && playUrl !== SOUNDCLOUD_NOTHING;
  const IS_SOUNDCLOUD_TRACK_FAVORITED =
    IS_SOUNDCLOUD_TRACK_SELECTED && favorites.map((fav) => fav?.playUrl).includes(playUrl);

  const removeFromFavorite = (playUrl) => removeSoundcloudFavorite(soundcloudDispatch, playUrl);
  const addToFavorite = (playUrl, playType, thumbnail, title) =>
    addSoundcloudFavorite(soundcloudDispatch, playUrl, playType, thumbnail, title);

  const toggleSearchPopover = () => {
    setIsSearchPopoverOpen(!isSearchPopoverOpen);
    setIsFavoritePopoverOpen(false);
  };

  const toggleFavoritePopover = () => {
    setIsFavoritePopoverOpen(!isFavoritePopoverOpen);
    setIsSearchPopoverOpen(false);
  };
  const renderFavoriteButton = () => {
    if (!IS_SOUNDCLOUD_TRACK_SELECTED) {
      return <></>;
    }

    if (IS_SOUNDCLOUD_TRACK_FAVORITED) {
      return (
        <div className="tooltip">
          <div
            className=" cursor-pointer"
            onClick={() => removeFromFavorite(playUrl)}
            role="button"
            aria-label="remove track from favorite"
          >
            <SoundcloudHeartIcon />
          </div>
          <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-150px' }}>
            Remove from playlist
          </span>
        </div>
      );
    }

    return (
      <div className="tooltip">
        <div
          className=" cursor-pointer"
          onClick={() => addToFavorite(playUrl, playType, thumbnail, title)}
          role="button"
          aria-label="add track to favorite"
        >
          <IsFavIcon />
        </div>
        <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-105px' }}>
          Add to playlist
        </span>
      </div>
    );
  };

  return (
    <div className="flex p-2 common-soundcloud-width main-background justify-between items-center">
      <div className="flex items-center gap-2">
        <SoundcloudFavorites
          favorites={favorites}
          isPopoverOpen={isFavoritePopoverOpen}
          togglePopoverOpen={toggleFavoritePopover}
          removeFromFavorite={removeFromFavorite}
          addToFavorite={addToFavorite}
        />
        <SoundcloudSearchOption isPopoverOpen={isSearchPopoverOpen} togglePopoverOpen={toggleSearchPopover} />
      </div>
      <div>
        <SoundcloudIcon width="25px" height="25px " />
      </div>
      <div className="flex gap-2 items-center">
        {renderFavoriteButton()}
        <div className="cursor-pointer" onClick={toggleSoundcloud} role="button" aria-label="close soundcloud">
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};

const SoundcloudPlayer = () => {
  const [play] = useSoundcloudContext();

  if (play.playType === SOUNDCLOUD_NOTHING) {
    return <></>;
  }

  let src = play.playUrl;

  return (
    <iframe
      id="sc-widget"
      width="100%"
      height="215"
      frameborder="no"
      src={`https://w.soundcloud.com/player/?visual=true&url=${src}&show_artwork=true`}
    ></iframe>
  );
};

const Soundcloud = ({ toggleSoundcloud }) => {
  return (
    <div className="flex flex-col main-background common-soundcloud-width" style={{ alignSelf: 'start' }}>
      <SoundcloudContextProvider>
        <SoundcloudHeader toggleSoundcloud={toggleSoundcloud} />
        <SoundcloudPlayer />
      </SoundcloudContextProvider>
    </div>
  );
};

export default Soundcloud;
