import { h, Component, render } from 'preact';
import { useState } from 'preact/hooks';

const useModal = (initialMode = false) => {
  const [isOpen, setIsOpen] = useState(initialMode);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return [isOpen, openModal, closeModal];
};

export default useModal;
