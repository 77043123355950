import { h, render, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import dayjs from 'dayjs';
import Modal from '../Modal/Modal';
import Calendar from '../Calendar/Calendar';
import ReactModal from '../ReactModal/ReactModal';

const TimeAndDate = () => {
  const [time, setTime] = useState(dayjs().format('HH:mm'));
  const tick = () => setTime(dayjs().format('HH:mm'));
  useEffect(() => {
    const intervalID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return (
    <>
      <h3>{time}</h3>
      <h3>{dayjs().format('MMM DD, YYYY')}</h3>
    </>
  );
};

const Timer = () => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const openCalendar = () => setIsCalendarOpen(true);
  const closeCalendar = () => setIsCalendarOpen(false);

  return (
    <div
      className="
      main-background
      p-4 radiant  overflow-y-hidden overflow-x-hidden
      text-xl self-end
      cursor-pointer border-shadow-pr
      "
      onClick={openCalendar}
    >
      <TimeAndDate />
      <ReactModal isOpen={isCalendarOpen} closeModal={closeCalendar} title={'Calendar'}>
        <Calendar closeCalendar={closeCalendar}/>
      </ReactModal>
    </div>
  );
};

export default Timer;
