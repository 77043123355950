import { createContext } from 'preact';
import { h, Fragment, Component, render } from 'preact';
import { useContext, useEffect, useMemo, useReducer } from 'preact/hooks';
import { ITEMS } from '../../helpers/items-constant';
import { useLocalStorageState } from '../customHooks/useLocalStorageState';

const TrashBoxContext = createContext([]);

const ADD_TO_TRASHBOX = 'add to trash';
const EMPTY_TRASHBOX = 'empty trashbox';
const REMOVE_FROM_TRASHBOX = 'remove from trashbox';

/*
Trash Box State
{trashBox: [
    {
        type: 'sticky note',
        uuid: 1233
    },
    {
        type: 'calendar event',
        uuid: 45667
    }
],
}
*/

function trashBoxReducer(state, action) {
  switch (action.type) {
    case ADD_TO_TRASHBOX:
      return {
        ...state,
        trashBox: [
          ...state.trashBox,
          {
            type: action.payload.type,
            uuid: action.payload.uuid
          }
        ]
      };
    case EMPTY_TRASHBOX:
      return {
        ...state,
        trashBox: []
      };
    case REMOVE_FROM_TRASHBOX:
      return {
        ...state,
        trashBox: state.trashBox.filter((item) => item.uuid !== action.payload.uuid)
      };
  }
}

export function TrashBoxContextProvider(props) {
  const [trashBoxLocalStorage, setTrashBoxLocalStorage] = useLocalStorageState(ITEMS.trashBox, {
    trashBox: []
  });

  const [state, dispatch] = useReducer(trashBoxReducer, trashBoxLocalStorage);

  useEffect(() => {
    setTrashBoxLocalStorage(state);
  }, [state, state?.trashBox, state?.trashBox.length]);

  const value = useMemo(() => [state, dispatch], [state, dispatch]);
  return <TrashBoxContext.Provider value={value} {...props} />;
}

export function useTrashBoxContext() {
  const context = useContext(TrashBoxContext);
  if (context === undefined) {
    throw new Error('useTrashBox Context must be within a TrashContextProvider');
  }
  return context;
}

export function addStickyNoteToTrash(dispatch, uuid) {
  dispatch({
    type: ADD_TO_TRASHBOX,
    payload: {
      type: ITEMS.stickyNotes,
      uuid: uuid
    }
  });
}

export function addCalendarEventToTrash(dispatch, uuid) {
  dispatch({
    type: ADD_TO_TRASHBOX,
    payload: {
      type: ITEMS.calendar,
      uuid
    }
  });
}

export function removeStickyNoteFromTrash(dispatch, uuid) {
  dispatch({
    type: REMOVE_FROM_TRASHBOX,
    payload: {
      uuid
    }
  });
}

export function removeCalendarEventFromTrash(dispatch, uuid) {
  dispatch({
    type: REMOVE_FROM_TRASHBOX,
    payload: {
      uuid
    }
  });
}

export function emptyTrash(dispatch) {
  dispatch({
    type: EMPTY_TRASHBOX
  });
}
