import { h, Component, render } from 'preact';
import { useState, useRef, useEffect } from 'preact/hooks';
import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from '../../utils/localstorage';

export const useLocalStorageState = (
  key,
  defaultValue = '',
  { serialize = JSON.stringify, deserialize = JSON.parse } = {}
) => {
  const [state, setState] = useState(() =>
    getFromLocalStorage({
      key,
      deserialize,
      defaultValue
    })
  );
  const prevKeyRef = useRef(key);

  useEffect(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      removeFromLocalStorage(key);
    }
    prevKeyRef.current = key;
    setToLocalStorage({
      key,
      value: state,
      serialize
    });
  }, [key, state, serialize]);

  return [state, setState];
};
