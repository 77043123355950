import { h, Fragment, Component, render } from 'preact';
import {
  emptyTrash,
  removeCalendarEventFromTrash,
  removeStickyNoteFromTrash,
  useTrashBoxContext
} from '../../context/trashBox';
import EmptyTrashIcon from '../Icons/EmptyTrashIcon';
import FullTrashIcon from '../Icons/FullTrashIcon';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { ITEMS, TRASH_BOX_OPEN_STATE } from '../../../helpers/items-constant';
import {
  handlePermanentlyDeleteStickyNoteAction,
  handleRestoreStickyNoteAction,
  useStickyNoteBoxContext
} from '../../context/stickyNoteBox';
import { truncateText } from '../../../helpers';
import { handleDeleteReminderAction, useRemindersContext } from '../../context/reminders';
import RecycleIcon from '../Icons/RecycleIcon';
import { useLocalStorageState } from '../../customHooks/useLocalStorageState';
import {
  handlePermanentlyDeleteCalendarEventAction,
  handleRestoreCalendarevent,
  useCalendarContext
} from '../../context/calendar';

const NoteRow = ({note, restoreNote,uuid, handlePermanentDelete}) => {
    return (
        <div className='flex gap-2 justify-between'>
            <span>{truncateText(note,15)}</span>
            <span className='flex gap-2'>
                <button onClick={() =>restoreNote(uuid)} className="green-svg cursor-pointer">
                    <RecycleIcon />
                </button>
                <button onClick={() => handlePermanentDelete(uuid)} className="red-svg cursor-pointer">
                    <EmptyTrashIcon />
                </button>
            </span>
        </div>
    )
}


const TrashBox = ({
  trashBox,
  trashBoxDispatch,
  stickyNoteBox,
  calendarValues,
  dispatchStickyNoteBox,
  dispatchCalendar,
  remindersDispatch,
  closeTrashBox
}) => {
  const allTrashedItemsUUID = [...new Set([...trashBox.map(v => v.uuid)])]

  const restoreItem = (uuid) => {
      if (calendarValues.events[uuid]){
          handleRestoreCalendarevent(dispatchCalendar,uuid)
          removeCalendarEventFromTrash(trashBoxDispatch, uuid);
      }
      if (stickyNoteBox.stickyNotes[uuid]){
          handleRestoreStickyNoteAction(dispatchStickyNoteBox,uuid);
          removeStickyNoteFromTrash(trashBoxDispatch,uuid);
      }
  }

     const fetchNoteData = (uuid) => {
         if(calendarValues.events[uuid]){
             return calendarValues.events[uuid].note
         }
         if(stickyNoteBox.stickyNotes[uuid]){
             return stickyNoteBox.stickyNotes[uuid].note
         }
     }

  if (allTrashedItemsUUID.length === 0) {
      
    return (<div className='p-2 main-background rounded-lg text-center' style={{maxWidth:'200px'}}>
        <h1 className="text-lg ">No Sticky notes to restore</h1>
        </div>)
  }

  const handleEmptyAll = () => {
    emptyTrash(trashBoxDispatch);
    for (let i = 0; i < allTrashedItemsUUID.length; i++) {
      const uuid = allTrashedItemsUUID[i];
      handleDeleteReminderAction(remindersDispatch, uuid);
      handlePermanentlyDeleteStickyNoteAction(dispatchStickyNoteBox, uuid);
      handlePermanentlyDeleteCalendarEventAction(dispatchCalendar, uuid);
    }
    closeTrashBox();
  };

  const handlePermanentDelete = (uuid) => {
    handleDeleteReminderAction(remindersDispatch, uuid);
    removeStickyNoteFromTrash(trashBoxDispatch, uuid);
    handlePermanentlyDeleteStickyNoteAction(dispatchStickyNoteBox, uuid);
    handlePermanentlyDeleteCalendarEventAction(dispatchCalendar, uuid);
    if (allTrashedItemsUUID?.length <= 1) {
      closeTrashBox();
    }
  };

  return (
    <div className="p-2 main-background rounded-lg flow-content">
      <button onClick={handleEmptyAll} className="text-red-600 cursor-pointer">
        <pre className='helveticIt'>Empty All</pre>
      </button>
      <hr />
      {allTrashedItemsUUID.map(uuid => (
        <NoteRow
            uuid={uuid}
            note={fetchNoteData(uuid)}
            restoreNote={restoreItem}
            handlePermanentDelete={handlePermanentDelete}
            
          />
      ))}
    </div>
  );
};
const Trash = () => {
  const [trashBoxState, trashBoxDispatch] = useTrashBoxContext();
  const [, remindersDispatch] = useRemindersContext();
  const [isPopoverOpen, setIsPopoverOpen] = useLocalStorageState(TRASH_BOX_OPEN_STATE, false);
  const [stickyNoteBox, dispatchStickyNoteBox] = useStickyNoteBoxContext();
  const [calendarValues, dispatchCalendar] = useCalendarContext();

  const { trashBox } = trashBoxState;

  const closeTrashBox = () => setIsPopoverOpen(false);

  const toggleIsPopoverOpen = () => {
      setIsPopoverOpen(!isPopoverOpen);
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['up']}
      padding={10}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'white'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <TrashBox
            trashBox={trashBox}
            trashBoxDispatch={trashBoxDispatch}
            stickyNoteBox={stickyNoteBox}
            calendarValues={calendarValues}
            dispatchStickyNoteBox={dispatchStickyNoteBox}
            dispatchCalendar={dispatchCalendar}
            remindersDispatch={remindersDispatch}
            closeTrashBox={closeTrashBox}
          />
        </ArrowContainer>
      )}
    >
      <div className={` cursor-pointer ${!isPopoverOpen ? 'tooltip white-svg' : 'green-svg'}`} onClick={toggleIsPopoverOpen}>
        {trashBox && trashBox.length > 0 && <FullTrashIcon width="25px" height="25p"/>}
        {trashBox && trashBox.length === 0 && <EmptyTrashIcon width="25px" height="25px"/>}
        {!isPopoverOpen && 
            <span className='tooltiptext shawarma'>Restore sticky notes</span>
        }
      </div>
    </Popover>
  );
};

export default Trash;
