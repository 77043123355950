import { h, Fragment, Component, render } from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { WeatherAPI } from '../../../services/Weather';
import { useAsync } from '../../customHooks/useAsync';
import { useLocalStorageState } from '../../customHooks/useLocalStorageState';
import CurrentLocationIcon from '../Icons/CurrentLocation';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import debounce from 'lodash.debounce';
import MoreDetailsIcon from '../Icons/MoreDetails';
import './weather.css';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import SettingIcon from '../Icons/SettingIcon.jsx';
import CloseIcon from '../Icons/CloseIcon';

const WEATHER_LOCATION_STORAGE = 'WEATHER_LOCATION_STORAGE';
const WEATHER_TO_SHOW_IN = 'Weather to show in';
const CURRENT_LOCATION = 'CURRENT_LOCATION';
const MANUAL_LOCATION = 'MANUAL_LOCATION';
const NONE = 'NONE';

const WEATHER_LOCATION_TYPE = {
  currentLocation: CURRENT_LOCATION,
  manualLocation: MANUAL_LOCATION,
  none: NONE
};

/*

  DATA REPRESENTATION

  Main Component:

  Current Selected Location Type = [CURRENT_LOCATION, MANUAL_LOCATION, NONE]

  Current Selected City Value = [] {string | object(lat and lon)}

  Current Weather = {}

  flow:
    always need to find the current weather

    on component mount, ask for location permission
    if permission given, 
        find out lat and lon
        change the selected location type to browser_native
        fetch the weather report
        set the weather

  UI VIEW:
    NO WEATHER SELECTED

    NO CITY WEATHER : {NAME}

    ERROR 

    WEATHER 
    
*/

const NoWeather = () => {
  return <div className="w-full p-2 text-center">Please choose a location.</div>;
};

const NoCity = () => {
  return <div className="w-full p-2 text-center">Sorry , city does not exist</div>;
};

const WeatherError = () => {
  return <div className="w-full p-2 text-center">Sorry, facing some issues</div>;
};

const celsiusToFahr = (c) => (c * 9) / 5 + 32;
const roundUp = (v) => Math.round(v);

const WeatherPreview = ({ data, isFahr }) => {
  const { main, name, weather } = data;
  const { description, icon } = weather[0];

  const { temp } = main;

  const tempToShow = roundUp(isFahr ? celsiusToFahr(temp) : temp);
  const tempText = isFahr ? 'F' : 'C';

  const redirectToMSN = () => {
    window.open(`https://www.msn.com/en-us/weather/forecast/in-${name}`);
  };

  return (
    <div className="flex gap-2 text-center items-center columnize-it">
      <div className="flex gap-2 items-center columnize-it">
        <span className="capitalize">{name}</span>
        <img
          width={50}
          height={50}
          src={`https://openweathermap.org/img/wn/${icon}@4x.png`}
          alt={`image for ${description}`}
        />
      </div>
      <div className="flex gap-2">
        <span>
          <span className="cursor-pointer">
            {tempToShow}°{tempText}
          </span>{' '}
          {description}
        </span>
        <span className="cursor-pointer" onClick={redirectToMSN}>
          <MoreDetailsIcon />
        </span>
      </div>
    </div>
  );
};

const fetchWeatherFromAPI = async (params) => {
  const result = await WeatherAPI.fetchWeather(params);
  if (result.success) {
    return result.data;
  } else {
    throw new Error(result?.error?.message || 'Sorry , could not fetch the weather');
  }
};

const fetchCitySuggestions = async (inputValue) => {
  try {
    if (!inputValue || inputValue.trim === '') {
      return [];
    }
    const result = await WeatherAPI.fetchCitySuggestions({ q: inputValue, limit: 100 });
    const { data } = result;

    const formatedData = data.map(({ city }, idx) => {
      return {
        id: `${city}+${idx}`,
        name: city
      };
    });

    return formatedData;
  } catch (e) {
    console.log(e);
    return [];
  }
};

function getLocation(handleSuccess, handleFailure) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(handleSuccess, handleFailure);
  } else {
    handleFailure({
      code: 'Custom',
      message: 'No Geo Location Service'
    });
  }
}

const SuggestedCity = ({ name, isSelected, handleOnSelect }) => {
  const suggestedCityRef = useRef();

  useEffect(() => {
    if (isSelected) {
      /**
       *  The below code exists, because when the user scroll
       *  down the suggested city list, eventually our element will be
       *  out of view.
       *
       *  The below code finds the node which is overflowing and makes
       *  sure our current element is in the view.
       */

      suggestedCityRef.current.parentNode.parentNode.scrollTop = suggestedCityRef.current.offsetTop - 30;
    }
  }, [isSelected]);
  return (
    <div
      className={`suggested-city-item cursor-pointer px-1 rounded-md w-full ${
        isSelected && 'suggested-city-item--selected'
      }`}
      onClick={() => handleOnSelect(name)}
      ref={suggestedCityRef}
    >
      {name}
    </div>
  );
};

const CitySuggestionListUI = ({ cities, handleOnSelect, suggestedIdx }) => {
  return (
    <div className="flex flex-col gap-1 w-full" style={{ maxHeight: '200px' }}>
      {cities.map((c, idx) => (
        <SuggestedCity handleOnSelect={handleOnSelect} isSelected={idx === suggestedIdx} name={c.name} />
      ))}
    </div>
  );
};

const WeatherHeader = ({ fetchWeatherByQuery, locationType, fetchWeatherByCurrentLocation, isFahr, toggleIsFahr }) => {
  const [citySuggestions, setCitySuggestions] = useState(() => []);
  const [text, setText] = useState('');
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const getSuggestions = async (text) => {
    const result = await fetchCitySuggestions(text);
    setCitySuggestions(result);
  };

  const debouncedGetSuggestions = useCallback(
    debounce((text) => {
      setActiveSuggestion(-1);
      getSuggestions(text);
    }, 200),
    []
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (text && text.trim() !== '') {
      fetchWeatherByQuery(text.trim());
    }
  };

  const handleOnSelect = (item) => {
    fetchWeatherByQuery(item);
  };

  const handleOnSearch = (string) => {
    setText(string);
    debouncedGetSuggestions.cancel();
    debouncedGetSuggestions(string.trim());
  };

  const handleDownArrow = () => {
    if (citySuggestions.length === 0) {
      setActiveSuggestion(-1);
    } else if (activeSuggestion < citySuggestions.length - 1) {
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  const handleUpArrow = () => {
    if (citySuggestions.length === 0) {
      setActiveSuggestion(-1);
    } else if (activeSuggestion > -1) {
      setActiveSuggestion(activeSuggestion - 1);
    }
  };

  const handleEnterKey = () => {
    if (activeSuggestion > -1 && activeSuggestion < citySuggestions.length) {
      const selectedItem = citySuggestions[activeSuggestion].name;
      fetchWeatherByQuery(selectedItem);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      handleDownArrow();
    } else if (e.key === 'ArrowUp') {
      handleUpArrow();
    } else if (e.key === 'Enter') {
      handleEnterKey();
    }
  };

  useEffect(() => {
    return () => debouncedGetSuggestions.cancel();
  }, []);

  return (
    <>
      <div className="relative flex main-background gap-2 w-full items-center rounded-lg" style={{ border: 0 }}>
        <div className="tooltip">
          <span
            onClick={fetchWeatherByCurrentLocation}
            className={`${locationType === CURRENT_LOCATION ? 'green-svg' : 'black-svg'} cursor-pointer `}
          >
            <CurrentLocationIcon />
          </span>
          <span className="tooltiptext tooltiptext--right">Your local temperature</span>
        </div>
        <div className="text-black w-full items-center flex truncate border-style-one rounded-lg">
          <form onSubmit={handleSubmit} className="relative">
            <input
              className="w-full m-auto outline-none px-2"
              type="text"
              value={text}
              onChange={(e) => handleOnSearch(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Type to search"
            />
          </form>
          <div onClick={toggleIsFahr} className={`cursor-pointer  temperature-options`}>
            <div className="text-lg cursor-pointer temperature-options__current-temp">°{isFahr ? 'F' : 'C'}</div>
          </div>
        </div>
      </div>
      {citySuggestions.length > 0 && (
        <div className="absolute bg-white" style={{ left: '30px', width: '160px' }}>
          <div
            className="p-2 border-2 rounded-md w-full"
            style={{ maxHeight: '100px', overflow: 'scroll', scrollBehavior: 'smooth' }}
          >
            <CitySuggestionListUI
              suggestedIdx={activeSuggestion}
              handleOnSelect={handleOnSelect}
              cities={citySuggestions}
            />
          </div>
        </div>
      )}
    </>
  );
};

const Weather = () => {
  const [selectedLocation, setLocation] = useLocalStorageState(WEATHER_LOCATION_STORAGE, {
    locationType: NONE,
    locationValue: null
  });

  const [isFahr, setIsFahr] = useLocalStorageState(WEATHER_TO_SHOW_IN, true);

  const toggleIsFahr = () => setIsFahr(!isFahr);

  const {
    data: weatherData,
    status: weatherStatus,
    error: weatherError,
    run
  } = useAsync({
    status: 'idle'
  });

  function fetchWeather(params) {
    run(fetchWeatherFromAPI(params));
  }

  function fetchWeatherByLatitudes({ lat, lon }) {
    fetchWeather({
      lat,
      lon
    });
  }

  function fetchWeatherByQuery(q) {
    setLocation({
      locationType: WEATHER_LOCATION_TYPE.manualLocation,
      locationValue: {
        query: q
      }
    });
    fetchWeather({
      q
    });
  }

  function handleSuccessfulLocation(position) {
    const longitude = position.coords.longitude;
    const latitude = position.coords.latitude;
    setLocation({
      locationType: WEATHER_LOCATION_TYPE.currentLocation,
      locationValue: {
        longitude,
        latitude
      }
    });
    fetchWeatherByLatitudes({ lat: latitude, lon: longitude });
  }

  function handleFailureLocation(data) {
    console.log('Cannot find or get geolocation api');
  }

  function fetchWeatherByCurrentLocation() {
    getLocation(handleSuccessfulLocation, handleFailureLocation);
  }

  const getWeatherHeader = () => (
    <WeatherHeader
      fetchWeatherByQuery={fetchWeatherByQuery}
      locationType={selectedLocation.locationType}
      fetchWeatherByCurrentLocation={fetchWeatherByCurrentLocation}
      isFahr={isFahr}
      toggleIsFahr={toggleIsFahr}
    />
  );

  useEffect(() => {
    if (selectedLocation.locationType === NONE) {
      fetchWeatherByCurrentLocation();
    } else if (selectedLocation.locationType === WEATHER_LOCATION_TYPE.currentLocation) {
      fetchWeatherByCurrentLocation();
    } else {
      fetchWeatherByQuery(selectedLocation.locationValue.query);
    }
  }, []);

  if (weatherStatus === 'pending') {
    return (
      <div
        className="main-background relative p-2 radiant flex items-center justify-center"
        style={{ minWidth: '203px', minHeight: '174px' }}
      >
        <div>
          <LoadingSpinner minHeight={50} isBlueSpinner={true} />
        </div>
      </div>
    );
  }

  if (weatherError && selectedLocation.locationType === MANUAL_LOCATION) {
    return (
      <div className="main-background relative p-2 radiant" style={{ minWidth: '203px', minHeight: '174px' }}>
        {getWeatherHeader()}
        <NoCity />
      </div>
    );
  }

  if (weatherError) {
    return (
      <div className="main-background rounded-lg relative">
        {getWeatherHeader()}
        <WeatherError />
      </div>
    );
  }

  if (selectedLocation.locationType === NONE) {
    return (
      <div
        className="main-background rounded-lg relative"
        style={{
          boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;'
        }}
      >
        {getWeatherHeader()}
        <NoWeather />
      </div>
    );
  }

  if (!weatherData) {
    return (
      <div className="main-background rounded-lg relative">
        {getWeatherHeader()}
        <div className="w-full p-2 text-center">No Data</div>
      </div>
    );
  }

  return (
    <div className="main-background rounded-lg p-2 border-shadow-pr radiant relative">
      {getWeatherHeader()}
      <WeatherPreview data={weatherData} isFahr={isFahr} />
    </div>
  );
};

export default Weather;
