import DefaultLogo from '../images/search.svg';
import GoogleLogo from '../images/google.svg';
import BaiduLogo from '../images/baidu.svg';
import BingLogo from '../images/bing.png';
import DuckDuckGoLogo from '../images/duckduckgo.svg';
import YahooLogo from '../images/yahoo.svg';

export const searchList = [
  {
    name: 'Bigoloo',
    link: 'https://search.bigoloo.com',
    imagesLink: 'https://www.bing.com/images/search',
    videosLink: 'https://www.bing.com/videos/search',
    newsLink: 'https://www.bing.com/news/search',
    icon: DefaultLogo
  },
  {
    name: 'Google',
    link: 'https://google.com/search?q=',
    imagesLink: 'https://google.com/search?tbm=isch&q=',
    videosLink: 'https://google.com/search?tbm=vid&q=',
    newsLink: 'https://google.com/search?tbm=nws&q=',
    icon: GoogleLogo
  },
  {
    name: 'Yahoo',
    link: 'https://search.yahoo.com/search?p=',
    imagesLink: 'https://images.search.yahoo.com/search/images?p=',
    videosLink: 'https://video.search.yahoo.com/search/video;?p=',
    newsLink: 'https://news.search.yahoo.com/search?p=',
    icon: YahooLogo
  },
  {
    name: 'Bing',
    link: 'https://www.bing.com/search?q=',
    imagesLink: 'https://www.bing.com/images/search?q=',
    videosLink: 'https://www.bing.com/videos/search?q=',
    newsLink: 'https://www.bing.com/news/search?q=',
    icon: BingLogo
  },
  {
    name: 'Duckduckgo',
    link: 'https://duckduckgo.com/?q=',
    imagesLink: 'https://duckduckgo.com/?iar=images&iax=images&ia=images&q=',
    videosLink: 'https://duckduckgo.com/?iax=videos&ia=videos&q=',
    newsLink: 'https://duckduckgo.com/?iar=news&ia=news&q=',
    icon: DuckDuckGoLogo
  },
  {
    name: 'Baidu',
    link: 'https://www.baidu.com/s?ie=utf-8&f=8&rsv_bp=1&rsv_idx=1&tn=baidu&wd=',
    imagesLink:
      'https://image.baidu.com/search/index?tn=baiduimage&ps=1&ct=201326592&lm=-1&cl=2&nc=1&ie=utf-8&ie=utf-8&word=',
    videosLink: 'https://www.baidu.com/sf/vsearch?pd=video&tn=vsearch&ie=utf-8&wd=',
    newsLink: 'https://www.baidu.com/s?rtt=1&bsst=1&cl=2&tn=news&ie=utf-8&word=',
    icon: BaiduLogo
  }
];

export const SELECTED_OPTION = 'selectedOption';
export const SELECTED_SEARCH_ENGINE = 'selectedSearchEngine';
export const SELECTED_SETTINGS = 'selectedSettings';

export const SPOTIFY = 'SPOTIFY';
export const YOUTUBE = 'YOUTUBE';
export const NO_PLAYER = 'NO_PLAYER';
export const WIDGET_STICKY = 'sticky';
export const WIDGET_CLOCK = 'clock';
export const WIDGET_WEATHER = 'weather';
export const SOUNDCLOUD = 'soundcloud';

export const searchOption = [
  {
    name: 'All',
    value: 'link'
  },
  {
    name: 'Images',
    value: 'imagesLink'
  },
  {
    name: 'Videos',
    value: 'videosLink'
  },
  {
    name: 'News',
    value: 'newsLink'
  }
];

export const initialSettings = {
  widgets: {
    sticky: {
      show: false
    },
    feeds: {
      show: false
    },
    clock: {
      show: false
    },
    player: {
      show: NO_PLAYER
    },
    weather: {
      show: false
    },
    shortcuts: {
      show: false
    }
  }
};

export const WALLPAPER = 'WALLPAPER';
export const DEFAULT_WALLPAPER = {
  author_name: 'Oscar Nilsoon',
  image_link:
    'https://images.unsplash.com/photo-1428223501723-d821c5d00ca3?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNDQ1OTh8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjYzMjc4MTA&ixlib=rb-1.2.1&q=85',
  username: 'oscrse'
};
