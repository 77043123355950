import { h, Fragment, Component, render } from 'preact';
import Sticky from './components/Sticky/Sticky';
import Search from './components/Search/Search';

import Timer from './components/Timer/Timer';
import { useLocalStorageState } from './customHooks/useLocalStorageState';

import Youtube from './components/Youtube/Youtube';
import Soundcloud from './components/Soundcloud/Soundcloud';

import { useEffect } from 'preact/hooks';

import { ToastContainer } from 'react-toastify';
import AppLogo from './components/AppLogo/AppLogo';
import {
  SELECTED_SEARCH_ENGINE,
  searchList,
  SELECTED_SETTINGS,
  initialSettings,
  SPOTIFY,
  YOUTUBE,
  SOUNDCLOUD,
  WALLPAPER,
  DEFAULT_WALLPAPER,
  NO_PLAYER,
  WIDGET_STICKY,
  WIDGET_CLOCK,
  WIDGET_WEATHER
} from '../helpers/constant';
import WallpaperButton from './components/WallpaperButton/WallpaperButton';
import { TrashBoxContextProvider } from './context/trashBox';
import { StickyNoteBoxContextProvider } from './context/stickyNoteBox';
import Trash from './components/Trash/Trash';
import { RemindersContextProvider } from './context/reminders';
import Reminder from './components/Reminder/Reminder';
import SettingsToolbar from './components/Setting/SettingsToolbar';
import Weather from './components/Weather/weather';
import { TempRemindersContextProvider } from './context/currentReminders';
import Favourites from './components/Favourites/Favourites';
import { CalendarContextProvider } from './context/calendar';

const App = () => {
  const [selectedSearchEngine, setSelectedSearchEngine] = useLocalStorageState(SELECTED_SEARCH_ENGINE, searchList[0]);
  const [settings, setSettings] = useLocalStorageState(SELECTED_SETTINGS, initialSettings);
  const [wallpaper, setWallpaper] = useLocalStorageState(WALLPAPER, DEFAULT_WALLPAPER);

  function toggleWidget(widget) {
    setSettings((settings) => ({
      ...settings,
      widgets: {
        ...settings.widgets,
        [widget]: {
          ...settings.widgets[widget],
          show: !settings.widgets[widget].show
        }
      }
    }));
  }
  function togglePlayer(value) {
    setSettings((settings) => ({
      ...settings,
      widgets: {
        ...settings.widgets,
        player: {
          ...settings.widgets.player,
          show: value
        }
      }
    }));
  }

  function toggleYoutube() {
    if (settings.widgets.player.show === YOUTUBE) {
      togglePlayer(NO_PLAYER);
    } else {
      togglePlayer(YOUTUBE);
    }
  }

  function toggleSoundcloud() {
    if (settings.widgets.player.show === SOUNDCLOUD) {
      togglePlayer(NO_PLAYER);
    } else {
      togglePlayer(SOUNDCLOUD);
    }
  }

  function hasPlayer() {
    return settings.widgets.player.show !== NO_PLAYER;
  }

  function toggleStickyNote() {
    toggleWidget(WIDGET_STICKY);
  }

  function toggleClock() {
    toggleWidget(WIDGET_CLOCK);
  }

  function toggleWeather() {
    toggleWidget(WIDGET_WEATHER);
  }

  function checkPlayerShowing(player) {
    return settings.widgets.player.show === player;
  }

  function checkWidgetShowing(widget) {
    return settings?.widgets?.[widget]?.show;
  }

  function getPlayer() {
    switch (settings.widgets.player.show) {
      case SPOTIFY:
        return <div>Some spotify</div>;
      case YOUTUBE:
        return <Youtube toggleYoutube={toggleYoutube} />;
      case SOUNDCLOUD:
        return <Soundcloud toggleSoundcloud={toggleSoundcloud} />;
      default:
        return <div />;
    }
  }

  const dragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  function saveWallpaper(wallpaper) {
    try {
      setWallpaper(wallpaper);
    } catch (error) {
      alert(error);
    }
  }

  useEffect(() => {
    document.body.style.backgroundImage = `url('${wallpaper.image_link}')`;
  }, [wallpaper]);

  return (
    <>
      <main
        onDragOver={dragOver}
        className=" flex flex-col justify-between gap-2 py-4 px-4 overflow-hidden  min-h-screen relative"
      >
        <TrashBoxContextProvider>
          <StickyNoteBoxContextProvider>
            <RemindersContextProvider>
              <TempRemindersContextProvider>
                <CalendarContextProvider>
                  <>
                    <section
                      className="flex flex-col relative gap-4 sm:flex-row justify-between"
                      style={{ minHeight: '264px' }}
                    >
                      {settings.widgets.sticky.show ? <Sticky /> : <div />}
                      {getPlayer()}
                    </section>
                    <section
                      className="order-last justify-self-end flex justify-between"
                      style={{ minHeight: '146px' }}
                    >
                      <div className="flex gap-2">
                        <div className="flex flex-col gap-2 self-end">
                        {settings.widgets.weather.show ? (
                          <div style={{ minHeight: 'fit-content', alignSelf: 'flex-end', zIndex: 1, maxWidth: '203px' }}>
                            <Weather />
                          </div>
                        ) : (
                          <div />
                        )}
                          {settings.widgets.clock.show ? <Timer /> : <div />}
                          <WallpaperButton setWallpaper={saveWallpaper} />
                        </div>
                      </div>

                      <div className="centering flex  gap-4 self-end items-center relative" >
                              <Reminder />
                        <div className="flex items-end gap-2 wheel-details">
                          <SettingsToolbar
                            toggleYoutube={toggleYoutube}
                            toggleStickyNote={toggleStickyNote}
                            checkPlayerShowing={checkPlayerShowing}
                            checkWidgetShowing={checkWidgetShowing}
                            toggleClock={toggleClock}
                            toggleWeather={toggleWeather}
                            toggleSoundcloud={toggleSoundcloud}
                          />
                        </div>
                        <AppLogo />
                      </div>
                    </section>
                  </>
                </CalendarContextProvider>
              </TempRemindersContextProvider>
            </RemindersContextProvider>
          </StickyNoteBoxContextProvider>
        </TrashBoxContextProvider>

        <section className="flex justify-center flex-col gap-2 m-auto w-full items-center search-section fixated pointer-events-none">
          <div className="falafel " style={{ zIndex: '1' }}>
            <Search
              selectedSearchEngine={selectedSearchEngine}
              setSelectedSearchEngine={setSelectedSearchEngine}
              searchList={searchList}
            />
          </div>

          <div className="m-auto-perso perso ">
            <Favourites />
          </div>
        </section>
        <div className="flex flex-col gap-4 footer-text">
          <div className="m-auto text-white readable-text flex gap-1 text-center">
            Photo by
            <p
              title={`https://unsplash.com/${wallpaper.username}?utm_source=bigoloo&utm_medium=referral`}
              href={`https://unsplash.com/${wallpaper.username}?utm_source=bigoloo&utm_medium=referral`}
              className=""
            >
              <p>{wallpaper.author_name}</p>
            </p>
            on
            <p
              title={`https://unsplash.com/?utm_source=bigoloo&utm_medium=referral`}
              href={`https://unsplash.com/?utm_source=bigoloo&utm_medium=referral`}
              className=""
            >
              <p> Unsplash</p>
            </p>
          </div>

          <div className="flex justify-center p-0 text-white">
            <div>
              <a className="readable-text hover:text-green-600" target="_blank" href="https://bigoloo.com/privacy">
                Privacy policy
              </a>{' '}
              |{' '}
              <a className="readable-text hover:text-green-600" target="_blank" href="https://bigoloo.com/terms">
                Terms &amp; conditions &nbsp;{' '}
              </a>
            </div>
            <div className="readable-text"> All the rights reserved to © Axeite Media LLC</div>
          </div>
        </div>
      </main>

      <ToastContainer />
    </>
  );
};

export default App;
