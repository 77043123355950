import { h, Component, render } from 'preact';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'preact/hooks';

export const useCalendarDates = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs());

  /**
   *  Returns the current's month's first day
   */
  const firstDayOfTheMonth = useMemo(() => selectedDate.clone().startOf('month'), [selectedDate]);

  /**
   *  Returns the start date of the week from the week the first day of the month belongs to
   *  @example
   *  The first day of the month is 1 Jan X Year, which is Tuesday
   *  The function will return 31 Dec (X - 1) Year, which is Sunday
   */
  const firstDayOfFirstWeekOfMonth = useMemo(() => dayjs(firstDayOfTheMonth).startOf('week'), [firstDayOfTheMonth]);

  /**
   *  Returns 6 dates , each 1 week apart from the start date provided
   */
  const generateFirstDayOfEachWeek = useCallback((startDate) => {
    const dates = [startDate];
    for (let i = 1; i < 6; i++) {
      const date = startDate.clone().add(i, 'week');
      dates.push(date);
    }
    return dates;
  }, []);

  /**
   * Returns the week dates, given the starting date of the week
   */
  const generateWeek = useCallback((startWeekDate) => {
    const dates = [startWeekDate.toDate()];
    for (let i = 1; i < 7; i++) {
      const date = startWeekDate.clone().add(i, 'day').toDate();
      dates.push(date);
    }

    return dates;
  });

  /**
   *    Whenever the selected date is changed, first day of the month will
   *    change and so will the first day of the first week of the month.
   *
   *    We will use that date to generate all the dates of the month
   *
   */
  const generateDatesOfTheMonth = useMemo(() => {
    const weekStartDates = generateFirstDayOfEachWeek(firstDayOfFirstWeekOfMonth);
    return weekStartDates.map((startDate) => generateWeek(startDate));
  }, [generateFirstDayOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek]);

  const changeToPreviousMonth = useCallback(() => {
    setSelectedDate((date) => date.subtract(1, 'month'));
  }, []);

  const changeToNextMonth = useCallback(() => {
    setSelectedDate((date) => date.add(1, 'month'));
  }, []);

  return {
    selectedDate,
    generateDatesOfTheMonth,
    changeToPreviousMonth,
    changeToNextMonth
  };
};
