import { h, Fragment, Component, render } from 'preact';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import {
  handleDeleteReminderAction,
  handleRemoveAllRemindersAction,
  useRemindersContext
} from '../../context/reminders';
import AlarmClockIcon from '../Icons/AlarmClockIcon';
import { useEffect, useState } from 'preact/hooks';
import './reminder.css';
import { ITEMS } from '../../../helpers/items-constant';
import { useStickyNoteBoxContext } from '../../context/stickyNoteBox';
import { truncateText } from '../../../helpers';

import { updateTempReducers, useTempRemindersContext } from '../../context/currentReminders';
import { useCalendarContext } from '../../context/calendar';


const NoteRow = ({uuid, note, clearReminder}) => {
    return (
        <div className='flex gap-2 justify-between'>
            <span>{truncateText(note,15)}</span>
            <span className='flex gap-2'>
                <button onClick={() => clearReminder(uuid)} className="text-green-600 cursor-pointer">
                    ✔
                </button>
            </span>
        </div>
    )
}


const ReminderBox = ({ itemsToRemind, dispatchReminders, stickyNoteBox, closeReminderBox, calendarBox }) => {
  const stickyNotesReminders = itemsToRemind.filter((reminder) => reminder.type === ITEMS.stickyNotes);

  const fetchStickyNoteData = (uuid) => stickyNoteBox.stickyNotes[uuid].note;
  const fetchCalendarData = (uuid) => calendarBox.events[uuid].note;

  const fetchData = (uuid) => {
      if(stickyNoteBox.stickyNotes[uuid]){
          return fetchStickyNoteData(uuid)
      }
      else if (calendarBox.events[uuid]){
          return fetchCalendarData(uuid)
      }

      return ""
  }

  const clearAllReminders = () => {
    handleRemoveAllRemindersAction(dispatchReminders);
    closeReminderBox();
  };

  const clearReminder = (uuid) => {
    handleDeleteReminderAction(dispatchReminders, uuid);
    if (stickyNotesReminders.length <= 1) {
      closeReminderBox();
    }
  };

  return (
    <div className="p-2 main-background rounded-lg flow-content">
      <button onClick={clearAllReminders} className="text-blue-600 cursor-pointer">
        <pre> ✔ Clear All</pre>
      </button>
      <h1 className="text-lg">Past reminders:</h1>
      <hr />
      {stickyNotesReminders.map((reminder) => (
        <NoteRow note={fetchData(reminder.uuid)} uuid={reminder.uuid} clearReminder={clearReminder} />
      ))}
      <hr />
    </div>
  );
};

const getItemsToRemind = (reminderItems, stickyNotesState, calendarVals) =>
  reminderItems.reminders.filter((reminder) => {
    if (!stickyNotesState.accessible.includes(reminder.uuid) &&  !calendarVals.accessible.includes(reminder.uuid)) {
      return false;
    }
    return new Date() > new Date(reminder.reminderDate);
  });

const Reminder = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [remindersState, dispatchReminders] = useRemindersContext();
  const [, tempRemindersDispatch] = useTempRemindersContext();
  const [stickyNotesState] = useStickyNoteBoxContext();
  const [stickyNoteBox] = useStickyNoteBoxContext();
  const [calendarBox] = useCalendarContext();
  const [itemsToRemind, setItemsToRemind] = useState([]);
  const [renderFlag, setRenderFlag] = useState(false);

  const closeReminderBox = () => setIsPopoverOpen(false);

  useEffect(() => {
    /*
      So, the app needs to in real time , remind if there is a reminder 
      and the only way to do that is have some sort of infinite 
      event loop that checks if any reminder is due. 

      We cannot pass the state / set State inside setInterval, because it will use 
      the old value and not the new updated State.

      Instead, we use the renderFlag state, to rerender the component every 10 seconds
      and thus as a side effect , check if any reminder is due.
    */
    const renderFlag = setInterval(() => setRenderFlag((prev) => !prev), 30000);

    return () => {
      clearInterval(renderFlag);
    };
  }, []);

  useEffect(() => {
    const currentItemsToRemind = getItemsToRemind(remindersState, stickyNotesState, calendarBox);
    setItemsToRemind(currentItemsToRemind);
    updateTempReducers(
      tempRemindersDispatch,
      currentItemsToRemind.map((reminder) => reminder.uuid)
    );
  }, [renderFlag, remindersState, stickyNotesState]);

  useEffect(() => {
    function flashTitle(pageTitle, newTitle) {
      if (document.title === pageTitle) {
        document.title = newTitle;
      } else {
        document.title = pageTitle;
      }
    }

    const noOfItems = itemsToRemind.length;
    const pageTitle = 'Bigoloo';
    const newTitle = noOfItems === 0 ? pageTitle : `(${noOfItems}) Active Reminders`;

    const startFlashTitle = setInterval(() => flashTitle(pageTitle, newTitle), 1000);

    return () => {
      clearInterval(startFlashTitle);
    };
  }, [itemsToRemind]);

  return (
    <div className={`${!isPopoverOpen && 'tooltip'}`}>
        <Popover
          isOpen={isPopoverOpen}
          positions={['up']}
          onClickOutside={() => setIsPopoverOpen(false)}
          padding={10}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={'white'}
              arrowSize={10}
              arrowStyle={{ opacity: 1 }}
              className="popover-arrow-container"
              arrowClassName="popover-arrow"
            >
              {itemsToRemind.length > 0 && (
                <ReminderBox
                  itemsToRemind={itemsToRemind}
                  stickyNoteBox={stickyNoteBox}
                  dispatchReminders={dispatchReminders}
                  closeReminderBox={closeReminderBox}
                  calendarBox={calendarBox}
                />
              )}

              {itemsToRemind.length === 0 && <h1 className="text-lg main-background p-2">No Reminders to show</h1>}
            </ArrowContainer>
          )}
        >
          <div className="white-svg cursor-pointer" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
            {itemsToRemind && itemsToRemind.length > 0 && (
              <div className="relative">
                <span className="block invisible p-1 w-8 h-8">300</span>
                <span className="absolute top-2 right-0 reminder-notification bg-red-600   text-center rounded-full text-white">
                  {itemsToRemind.length}
                </span>
                <AlarmClockIcon />
              </div>
            )}
            {itemsToRemind && itemsToRemind.length === 0 && <AlarmClockIcon />}
          </div>
        </Popover>
      {!isPopoverOpen && 
      <span className='tooltiptext'>
          Past reminders
      </span>
      }
  </div>
  );
};

export default Reminder;
