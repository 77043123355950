export function setToLocalStorage({ key, value, serialize = JSON.stringify }) {
  window.localStorage.setItem(key, serialize(value));
}

export function removeFromLocalStorage(key) {
  window.localStorage.removeItem(key);
}

export function getFromLocalStorage({
  key,
  deserialize = JSON.parse,
  defaultValue = "",
}) {
  const valueInLocalStorage = window.localStorage.getItem(key);
  if (valueInLocalStorage) {
    try {
      return deserialize(valueInLocalStorage);
    } catch (error) {
      removeFromLocalStorage(key);
      return typeof defaultValue === "function" ? defaultValue() : defaultValue;
    }
  } else {
    return typeof defaultValue === "function" ? defaultValue() : defaultValue;
  }
}
