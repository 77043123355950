import { h, Fragment, Component, render } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { ReactSVG } from 'react-svg';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import {
  addYoutubeFavorite,
  NOTHING,
  playYoutubePlayList,
  playYoutubeVideo,
  removeYoutubeFavorite,
  useYoutubeContext,
  YoutubeContextProvider
} from '../../context/youtube';
import SearchIcon from '../Icons/SearchIcon';
import YoutubeIcon from '../Icons/YoutubeIcon';
import './youtube.css';
import { PLAYLIST, VIDEO } from '../../context/youtube';
import { useAsync } from '../../customHooks/useAsync';
import { YoutubeAPI } from '../../../services/Youtube';
import CloseIcon from '../Icons/CloseIcon';
import AddFavIcon from '../Icons/AddFavIcon';
import IsFavIcon from '../Icons/IsFavIcon';
import YoutubeHeartIcon from '../Icons/YoutubeHeart';

const YoutubePlayer = () => {
  const [play] = useYoutubeContext();

  if (play.playType === NOTHING) {
    return <></>;
  }

  let src =
    play.playType === PLAYLIST
      ? `https://www.youtube.com/embed/videoseries?list=${play.playUrl}`
      : `https://www.youtube.com/embed/${play.playUrl}`;

  return (
    <iframe
      width="300"
      height="215"
      src={src}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  );
};

const YoutubeSearchForm = ({ handleSearch, searchTerm, setSearchTerm, typeSelected, setTypeSelected }) => {
  const inputRef = useRef();
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const dataToSubmit = {
      query: searchTerm,
      type: typeSelected === PLAYLIST ? PLAYLIST : VIDEO
    };
    handleSearch(dataToSubmit);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);
  return (
    <div className="main-background min-w-250px p-4 common-youtube-width" style={{ border: 0 }}>
      <form onSubmit={handleFormSubmit} className="flow-content">
        <div className="flex flex-col gap-2">
          <label for="youtube-search">Search for a video</label>
          <input
            type="text"
            ref={inputRef}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="text-black border-style-one p-2 rounded-lg"
            name="youtube-search"
            placeholder="Type to search"
          ></input>
        </div>

        <div className="flex gap-2 justify-around">
          <div className="flex gap-2">
            <label for="video">Video</label>

            <input
              type="radio"
              id="video"
              name="search-option"
              value="video"
              checked={typeSelected === VIDEO}
              onChange={() => setTypeSelected(VIDEO)}
            ></input>
          </div>
          <div className="flex gap-2">
            <label for="playlist">Playlist</label>
            <input
              type="radio"
              id="playlist"
              name="search-option"
              value="playlist"
              checked={typeSelected === PLAYLIST}
              onChange={() => setTypeSelected(PLAYLIST)}
            ></input>
          </div>
        </div>
      </form>
    </div>
  );
};

const YOUTUBE_VIDEO_KIND = `youtube#video`;
const YOUTUBE_PLAYLIST_KIND = `youtube#playlist`;

const extractThumbnail = (youtubeItem) => youtubeItem?.snippet?.thumbnails?.default?.url;
const extractItemKind = (youtubeItem) => youtubeItem?.id?.kind;
const extractItemId = (youtubeItem, kind) => {
  if (kind === YOUTUBE_PLAYLIST_KIND) {
    return youtubeItem?.id?.playlistId;
  } else if (kind === YOUTUBE_VIDEO_KIND) {
    return youtubeItem?.id?.videoId;
  }
  return null;
};
const extractItemTitle = (youtubeItem) => youtubeItem?.snippet?.title;
const extractItemDescription = (youtubeItem) => youtubeItem?.snippet?.description;
const extractETag = (youtubeItem) => youtubeItem?.etag;

const UI_TYPE_SEARCH = 'UI_TYPE_SEARCH';
const UI_TYPE_FAV = 'UI_TYPE_FAV';

const YoutubeResultUI = ({
  itemId,
  itemKind,
  thumbnail,
  title,
  playItem,
  description,
  addToFavorite,
  removeItem,
  type = UI_TYPE_SEARCH,
  isFav = true
}) => (
  <div
    onClick={() => playItem(itemId, itemKind, thumbnail, title)}
    className="flex gap-1 p-2 cursor-pointer relative"
    style={{ maxWidth: '600px' }}
  >
    {thumbnail ? (
      <img src={thumbnail} alt={`Thumbnail for youtube's ${title}`} style={{ width: '100px', height: '100px' }} />
    ) : (
      'No Thumbnail'
    )}

    <div className="w-full flex flex-col gap-1">
      {title ? <h3 className="w-full font-bold">{title}</h3> : <h3>Unknown Title</h3>}

      {description && <p className="w-full">{description}</p>}
    </div>

    {isFav && (
      <div className="tooltip cursor-pointer" style={{ alignSelf: 'start' }}>
        <button
          onClick={(e) => {
            e.stopPropagation();
            removeItem({
              itemId,
              itemKind,
              thumbnail,
              title,
              playItem,
              description
            });
          }}
          style={{ height: '25px' }}
        >
          <YoutubeHeartIcon width="20px" height="20px" />
        </button>
        <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-150px' }}>
          Remove from playlist
        </span>
      </div>
    )}

    {!isFav && type === UI_TYPE_SEARCH && (
      <div className="tooltip cursor-pointer" style={{ alignSelf: 'start' }}>
        <button
          onClick={(e) => {
            e.stopPropagation();
            addToFavorite({ playUrl: itemId, playType: itemKind, thumbnail, title });
          }}
          style={{ height: '25px' }}
        >
          <IsFavIcon width="20px" height="20px" />
        </button>
        <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-105px' }}>
          Add to playlist
        </span>
      </div>
    )}
  </div>
);

const YoutubeSingleResult = ({ youtubeItem, playItem, favorites, removeItem, addToFavorite }) => {
  const thumbnail = extractThumbnail(youtubeItem);
  const itemKind = extractItemKind(youtubeItem);
  const itemId = extractItemId(youtubeItem, itemKind);
  const title = extractItemTitle(youtubeItem);
  const description = extractItemDescription(youtubeItem);
  const isFav = favorites.includes(itemId);

  if (!itemId) {
    return <div className="main-background p-2">Corrupted result</div>;
  }

  return (
    <YoutubeResultUI
      itemId={itemId}
      itemKind={itemKind}
      thumbnail={thumbnail}
      title={title}
      playItem={playItem}
      addToFavorite={addToFavorite}
      description={description}
      isFav={isFav}
      removeItem={removeItem}
    />
  );
};

const YoutubeSearchResult = ({ searchStatus, searchError, searchData, handlePageChange }) => {
  const [youtubeState, youtubeDispatch] = useYoutubeContext();

  const favorites = (youtubeState?.favorites ?? []).map((fav) => fav?.playUrl);

  const playItem = (itemId, itemKind, thumbnail, title) => {
    if (itemKind === YOUTUBE_PLAYLIST_KIND) {
      playYoutubePlayList(youtubeDispatch, itemId, thumbnail, title);
    }
    if (itemKind === YOUTUBE_VIDEO_KIND) {
      playYoutubeVideo(youtubeDispatch, itemId, thumbnail, title);
    }
  };

  const removeItem = (item) => removeYoutubeFavorite(youtubeDispatch, item.itemId);
  const addToFavorite = ({ playUrl, playType, thumbnail, title }) => {
    let type = '';
    if (playType === YOUTUBE_VIDEO_KIND) {
      type = VIDEO;
    }
    if (playType === YOUTUBE_PLAYLIST_KIND) {
      type = PLAYLIST;
    }

    addYoutubeFavorite(youtubeDispatch, playUrl, type, thumbnail, title);
  };

  const youtubeItems = searchData?.data?.items;
  const nextPageToken = searchData?.data?.nextPageToken;
  const prevPageToken = searchData?.data?.prevPageToken;

  if (searchStatus === 'pending') {
    return <div className="main-background common-youtube-width">Loading...</div>;
  }

  if (!youtubeItems) {
    return <div className="main-background p-4">Nothing matching the search found.</div>;
  }

  return (
    <div className="main-background p-4 flex flex-col gap-2 max-w-xl max-h-96 overflow-auto common-youtube-width	">
      {youtubeItems.map((item) => (
        <YoutubeSingleResult
          removeItem={removeItem}
          youtubeItem={item}
          favorites={favorites}
          key={extractETag(item)}
          addToFavorite={addToFavorite}
          playItem={playItem}
        />
      ))}
      <div className="flex justify-between w-full">
        {prevPageToken && <button onClick={() => handlePageChange(prevPageToken)}> Prev Page</button>}
        {nextPageToken && (
          <button onClick={() => handlePageChange(nextPageToken)} className="ml-auto">
            {' '}
            Next Page
          </button>
        )}
      </div>
    </div>
  );
};

const YoutubeSearchContainer = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [typeSelected, setTypeSelected] = useState(VIDEO);
  const [showSearch, setShowSearch] = useState(true);

  const { data, status, error, run } = useAsync({
    status: 'idle'
  });

  function searchYoutube(params) {
    run(YoutubeAPI.searchYoutube(params));
    setShowSearch(false);
  }

  const handleSearch = (dataToSubmit) => {
    searchYoutube(dataToSubmit);
  };

  const handlePageChange = (pageToken) =>
    handleSearch({ query: searchTerm, type: typeSelected === PLAYLIST ? PLAYLIST : VIDEO, pageToken });

  return (
    <>
      {showSearch && (
        <YoutubeSearchForm
          handleSearch={handleSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          typeSelected={typeSelected}
          setTypeSelected={setTypeSelected}
        />
      )}
      {!showSearch && (
        <div
          className="main-background w-full text-center cursor-pointer common-youtube-width"
          onClick={() => setShowSearch(true)}
        >
          Search Again
        </div>
      )}

      {!showSearch && (
        <YoutubeSearchResult
          searchStatus={status}
          searchError={error}
          searchData={data}
          searchYoutube={searchYoutube}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

const YoutubeSearchOption = ({ isPopoverOpen, togglePopoverOpen }) => {
  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['left']}
      onClickOutside={() => null}
      padding={45}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'white'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <YoutubeSearchContainer />
        </ArrowContainer>
      )}
    >
      <div className={`${!isPopoverOpen && 'tooltip'}`}>
        <button
          role="button"
          className={`${isPopoverOpen ? 'green-svg' : 'black-svg'} border-2 p-1 rounded-lg`}
          style={{ width: '25px', height: '25px', borderColor: `${isPopoverOpen ? '#338fc9' : 'grey'}` }}
          onClick={togglePopoverOpen}
        >
          <SearchIcon width="10px" height="10px" />
        </button>
        {!isPopoverOpen && (
          <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-65px' }}>
            Search
          </span>
        )}
      </div>
    </Popover>
  );
};

const NoYoutubeFavorites = () => {
  return <div className="p-2">Your favorite videos will show up here.</div>;
};

const YoutubeFavorites = ({ favorites, isPopoverOpen, togglePopoverOpen, removeFromFavorite, addToFavorite }) => {
  const favoritesContainerRef = useRef();

  const [, youtubeDispatch] = useYoutubeContext();

  const [removedItems, setRemovedItems] = useState([]);

  const playItem = (playUrl, itemKind, thumbnail, title) => {
    if (itemKind === PLAYLIST) {
      playYoutubePlayList(youtubeDispatch, playUrl, thumbnail, title);
    }
    if (itemKind === VIDEO) {
      playYoutubeVideo(youtubeDispatch, playUrl, thumbnail, title);
    }
  };

  const removeItem = (item) => {
    if (removedItems.length === 0) {
      setRemovedItems([...removedItems, item]);
    }
    favoritesContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const undoRemoval = (item) => {
    setRemovedItems(removedItems.filter((i) => i.itemId !== item.itemId));
  };

  const confirmRemoval = (item) => {
    setRemovedItems(removedItems.filter((i) => i.itemId !== item.itemId));
    removeFromFavorite(item.itemId);
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['left']}
      onClickOutside={() => null}
      padding={10}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'white'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div
            ref={favoritesContainerRef}
            className="main-background common-youtube-width overflow-y-auto"
            style={{ maxHeight: '350px' }}
          >
            {removedItems.length > 0 && (
              <div className="flex gap-1 mb-1 flex-col">
                {removedItems.map((item) => (
                  <div key={item.itemId} className="p-2 flex justify-between items-center">
                    <div className="flex gap-2 flex-col">
                      <span>Remove from playlist? </span>
                      <p className="font-bold" style={{ maxWidth: '300px' }}>
                        {item.title}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => confirmRemoval(item)}
                        className="items-center rounded-md p-2 text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                      <button
                        onClick={() => undoRemoval(item)}
                        className=" p-2 items-center rounded-md text-blue-500 hover:text-blue-700"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {favorites.length === 0 && <NoYoutubeFavorites />}
            {favorites.length > 0 &&
              favorites.map((fav) => (
                <YoutubeResultUI
                  itemId={fav.playUrl}
                  itemKind={fav.playType}
                  thumbnail={fav.thumbnail}
                  title={fav.title}
                  playItem={playItem}
                  removeItem={removeItem}
                  type={UI_TYPE_FAV}
                />
              ))}
          </div>
        </ArrowContainer>
      )}
    >
      <div className={`${!isPopoverOpen && 'tooltip'}`}>
        <button role="button" className=" p-1 rounded-lg text-white" onClick={togglePopoverOpen}>
          <AddFavIcon />
        </button>
        {!isPopoverOpen && (
          <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-90px' }}>
            Favorites list
          </span>
        )}
      </div>
    </Popover>
  );
};

const YoutubeHeader = ({ toggleYoutube }) => {
  const [isSearchPopoverOpen, setIsSearchPopoverOpen] = useState(false);
  const [isFavoritePopoverOpen, setIsFavoritePopoverOpen] = useState(false);
  const [youtubeState, youtubeDispatch] = useYoutubeContext();
  const favorites = youtubeState?.favorites ?? [];
  const playUrl = youtubeState?.playUrl ?? null;
  const playType = youtubeState?.playType ?? null;
  const thumbnail = youtubeState?.thumbnail ?? null;
  const title = youtubeState?.title ?? null;

  const IS_YOUTUBE_ITEM_SELECTED = Boolean(playUrl) && playUrl !== NOTHING;
  const IS_YOUTUBE_ITEM_FAVORITED = IS_YOUTUBE_ITEM_SELECTED && favorites.map((fav) => fav?.playUrl).includes(playUrl);

  const removeFromFavorite = (playUrl) => removeYoutubeFavorite(youtubeDispatch, playUrl);
  const addToFavorite = (playUrl, playType, thumbnail, title) =>
    addYoutubeFavorite(youtubeDispatch, playUrl, playType, thumbnail, title);

  const toggleSearchPopover = () => {
    setIsSearchPopoverOpen(!isSearchPopoverOpen);
    setIsFavoritePopoverOpen(false);
  };

  const toggleFavoritePopover = () => {
    setIsFavoritePopoverOpen(!isFavoritePopoverOpen);
    setIsSearchPopoverOpen(false);
  };

  const renderFavoriteButton = (
    IS_YOUTUBE_ITEM_FAVORITED,
    IS_YOUTUBE_ITEM_SELECTED,
    playUrl,
    playType,
    thumbnail,
    title
  ) => {
    if (!IS_YOUTUBE_ITEM_SELECTED) {
      return <></>;
    }
    if (IS_YOUTUBE_ITEM_FAVORITED) {
      return (
        <div className="tooltip">
          <div
            className=" cursor-pointer"
            onClick={() => removeFromFavorite(playUrl)}
            aria-label="remove youtube item from favorite"
          >
            <YoutubeHeartIcon />
          </div>
          <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-150px' }}>
            Remove from playlist
          </span>
        </div>
      );
    }

    return (
      <div className="tooltip">
        <div
          className="cursor-pointer"
          onClick={() => addToFavorite(playUrl, playType, thumbnail, title)}
          aria-label="remove youtube item from favorite"
        >
          <IsFavIcon />
        </div>
        <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-105px' }}>
          Add to playlist
        </span>
      </div>
    );
  };
  return (
    <div className="flex p-2 common-youtube-width main-background justify-between items-center">
      <div className="flex items-center gap-2">
        <YoutubeFavorites
          favorites={favorites}
          isPopoverOpen={isFavoritePopoverOpen}
          togglePopoverOpen={toggleFavoritePopover}
          removeFromFavorite={removeFromFavorite}
          addToFavorite={addToFavorite}
        />
        <YoutubeSearchOption isPopoverOpen={isSearchPopoverOpen} togglePopoverOpen={toggleSearchPopover} />
      </div>
      <div className="black-svg flex gap-2 items-center">
        <YoutubeIcon width="25px" height="25px" />
      </div>
      <div className="flex gap-2 items-center">
        {renderFavoriteButton(IS_YOUTUBE_ITEM_FAVORITED, IS_YOUTUBE_ITEM_SELECTED, playUrl, playType, thumbnail, title)}
        <div className="white-svg cursor-pointer" onClick={toggleYoutube} aria-label="close youtube">
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};

const Youtube = ({ toggleYoutube }) => {
  return (
    <div className="flex flex-col main-background common-youtube-width" style={{ alignSelf: 'start' }}>
      <YoutubeContextProvider>
        <YoutubeHeader toggleYoutube={toggleYoutube} />
        <YoutubePlayer />
      </YoutubeContextProvider>
    </div>
  );
};

export default Youtube;
