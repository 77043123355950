export function createReminder({ uuid, remindOn }) {
  return {
    uuid,
    remindOn
  };
}

export const isPastTime = (date) => {
  return new Date().getTime() > date.getTime();
};
export const filterTime = (date) => {
  return !isPastTime(date);
};
