import { h, Fragment, Component, render } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useLocalStorageState } from '../../customHooks/useLocalStorageState';
import Modal from '../Modal/Modal';
import './favourites.css';
import { useAsync } from '../../customHooks/useAsync';
import { VeveAPI } from '../../../services/Veve';
import useModal from '../../customHooks/useModal';
import CloseIcon from '../Icons/CloseIcon';

const FAV_SITES = 'fav sites';

const MARKETPLACE_SITE = 'marketplace';
const CUSTOM_SITE = 'custom site';

const Favourite = ({ fav, removeFavourite, swapVeveTitle }) => {
  const url = new URL(fav.url);
  const basehost = url.host;
  const imageSrc = `https://www.google.com/s2/favicons?domain=${basehost}&sz=128`;

  const redirectToUrl = () => {
    window.open(fav.url);
  };

  const deleteShortcut = (e) => {
    e.stopPropagation();
    removeFavourite(fav.url);
  };

  return (
    <div className="w-full shortcut-icon-container">
      <div
        className="flex flex-col m-auto items-center cursor-pointer text-sm wiggle shortcut falash text-center"
        style={{ maxWidth: 'fit-content' }}
        onClick={redirectToUrl}
      >
        <div className="shortcut-title">
          <pre>{fav.name}</pre>
        </div>
        <span>
          {fav.type === CUSTOM_SITE && (
            <img src={imageSrc} alt={`Favicon of ${fav.name} website`} style={{ width: '32px', height: '32px' }} />
          )}
          {fav.type === MARKETPLACE_SITE && (
            <img src={fav.imgUrl} alt={`Favicon of ${fav.name} website`} style={{ width: '32px', height: '32px' }} />
          )}
        </span>
        <span className="shortcut-down-arrow"></span>
        {fav.type === CUSTOM_SITE && (
          <span className="shortcut-close" onClick={deleteShortcut}>
            <CloseIcon width="5px" height="5px" />
          </span>
        )}
        {fav.type === MARKETPLACE_SITE && (
          <span
            className="shortcut-close"
            onClick={(e) => {
              e.stopPropagation();
              swapVeveTitle(fav.url);
            }}
          >
            <CloseIcon width="5px" height="5px" />
          </span>
        )}
      </div>
    </div>
  );
};

const err_msg = {
  url_invalid: 'URL is not valid',
  url_duplicate: 'Shortcut already exists',
  name: 'Name is not valid'
};

const FavouriteForm = ({
  closeModal,
  addNewFavourite = () => null,
  updateFavourite = () => null,
  favourites = [],
  initialValues = null,
  isEditing = false
}) => {
  const getInitialName = () => {
    if (isEditing && initialValues?.name) {
      return initialValues.name;
    }
    return '';
  };

  const getInitialUrl = () => {
    if (isEditing && initialValues?.url) {
      return initialValues.url;
    }
    return '';
  };

  const [name, setName] = useState(() => getInitialName());
  const [url, setUrl] = useState(() => getInitialUrl());
  const [errors, setErrors] = useState([]);

  const isValidUrl = (urlString) => {
    try {
      const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' +
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
          '((\\d{1,3}\\.){3}\\d{1,3}))' +
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
          '(\\?[;&a-z\\d%_.~+=-]*)?' +
          '(\\#[-a-z\\d_]*)?$',
        'i'
      );
      new URL(urlString);
      return !!urlPattern.test(urlString);
    } catch (err) {
      return false;
    }
  };

  const isNotDuplicate = (url) => {
    let filteredFavourites = [...favourites];
    if (isEditing) {
      filteredFavourites = favourites.filter((fav) => fav.url !== initialValues.url);
    }

    filteredFavourites = filteredFavourites.filter((fav) => fav.url === url);

    if (filteredFavourites.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const validName = (name) => {
    if (name && name.trim() !== '') {
      return true;
    } else {
      return false;
    }
  };

  const validFavouriteInput = ({ url, name }) => {
    const IS_VALID_NAME = validName(name);
    const IS_VALID_URL = isValidUrl(url);
    const IS_NOT_DUPLICATE_URL = isNotDuplicate(url);
    const errors = [];
    if (!IS_VALID_NAME) {
      errors.push(err_msg.name);
    }
    if (!IS_VALID_URL) {
      errors.push(err_msg.url_invalid);
    }
    if (IS_VALID_URL && !IS_NOT_DUPLICATE_URL) {
      errors.push(err_msg.url_duplicate);
    }

    if (IS_VALID_NAME && IS_VALID_URL && IS_NOT_DUPLICATE_URL) {
      return true;
    } else {
      setErrors(errors);
      return false;
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const name = e?.target?.[0].value;
    let url = e?.target?.[1].value;

    if (url) {
      url = url.match(/^http[s]?:\/\//) ? url : 'https://' + url;
    }

    const IS_VALID = validFavouriteInput({ url, name });

    if (!IS_VALID) {
      return;
    }

    if (isEditing) {
      updateFavourite({ oldUrl: getInitialUrl(), url, name });
    } else {
      addNewFavourite({ url, name });
    }

    closeModal();
  };
  console.log(errors);
  const handleUrlChange = (e) => {
    const newUrl = e.target.value;
    const urlToCheck = newUrl.match(/^http[s]?:\/\//) ? url : 'https://' + newUrl;
    const IS_URL_DUPLICATED = !isNotDuplicate(urlToCheck);
    console.log(isNotDuplicate(urlToCheck));
    if (IS_URL_DUPLICATED && !errors.includes(err_msg.url_duplicate)) {
      const newErr = [...errors];
      newErr.push(err_msg.url_duplicate);
      console.log(newErr);
      setErrors(newErr);
    } else {
      setErrors(errors.filter((err) => err !== err_msg.url_duplicate));
    }
    setUrl(newUrl);
  };

  return (
    <div style={{ width: '500px' }}>
      <h2 className="text-lg font-bold">Add Shortcut</h2>
      <form className="w-full flex gap-2 flex-col" onSubmit={handleFormSubmit}>
        <div className="p-2  flex flex-col w-full">
          <label for="shortcut-name text-white">Name</label>
          <input
            value={name}
            type="text"
            name="shortcut-name"
            onChange={(e) => setName(e.target.value)}
            className="text-black p-1 rounded-lg w-full border-style-one"
          />
        </div>
        <div className="p-2  flex flex-col w-full">
          <label for="shortcut-name text-white">URL</label>
          <input
            value={url}
            type="text"
            onChange={handleUrlChange}
            name="shortcut-url"
            className="text-black p-1 rounded-lg w-full border-style-one"
          />
        </div>
        <div className="justify-self-end self-end flex gap-2">
          <button type="submit">Save</button>
          <button onClick={closeModal}>Cancel</button>
        </div>
      </form>
      {errors && errors.length > 0 && (
        <div className="flex flex-col gap-2 text-red-400">
          <ul>
            {errors.map((e) => (
              <li key={e}>{e}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const AddFavourite = ({ addNewFavourite, favourites }) => {
  const [addModalOpen, openAddModal, closeAddModal] = useModal();

  return (
    <div className="w-full h-full p-2 cursor-pointer addShortcut " onClick={openAddModal}>
      +
      <Modal isOpen={addModalOpen} closeModal={closeAddModal}>
        <FavouriteForm closeModal={closeAddModal} addNewFavourite={addNewFavourite} favourites={favourites} />
      </Modal>
    </div>
  );
};

const transformVeveTiles = (veveTiles) => {
  if (!veveTiles || veveTiles.length === 0) {
    return [];
  }

  return veveTiles.map((tile) => {
    return {
      ...tile,
      type: MARKETPLACE_SITE
    };
  });
};

const fetchVeveTiles = async () => {
  const result = await VeveAPI.fetchMarketPlaceTiles();
  if (result.success) {
    return result.data;
  } else {
    return [];
  }
};

const Favourites = () => {
  const [savedFavourites, setSavedFavourites] = useLocalStorageState(FAV_SITES, []);

  const [favourites, setFavourites] = useState(savedFavourites);

  const [selectedVeveTiles, setSelectedVeveTiles] = useState([]);
  const [veveTitleInStock, setVeveTitleInStock] = useState([]);

  const swapVeveTitle = (swapUrl) => {
    if (veveTitleInStock.length === 0) {
      return;
    }

    const swapIndex = selectedVeveTiles.findIndex((val) => val.url === swapUrl);

    if (swapIndex < 0) {
      return;
    }

    const tileToReplace = selectedVeveTiles[swapIndex];
    const tileToReplaceWith = veveTitleInStock[0];

    const newViveTiles = veveTitleInStock.splice(1);
    newViveTiles.push(tileToReplace);

    const newSelectedVeveTiles = [...selectedVeveTiles];
    newSelectedVeveTiles[swapIndex] = tileToReplaceWith;

    setSelectedVeveTiles(newSelectedVeveTiles);
    setVeveTitleInStock(newViveTiles);
  };

  const {
    data: veveTiles,
    status: veveStatus,
    error: veveError,
    run
  } = useAsync({
    status: 'idle'
  });

  const addNewFavourite = ({ url, name }) => {
    if (savedFavourites.length < 6) {
      setSavedFavourites([
        ...savedFavourites,
        {
          url,
          name,
          type: CUSTOM_SITE
        }
      ]);
    }
  };

  const removeFavourite = (url) => {
    const newFavourites = savedFavourites.filter((fav) => fav.url !== url);
    setSavedFavourites(newFavourites);
  };

  useEffect(() => {
    run(fetchVeveTiles());
  }, []);

  useEffect(() => {
    /**
     *  Dividing the veveTiles we get to
     *  the ones we intially show and the
     *  one we keep in stock
     */
    if (veveTiles) {
      const toLoopFor = veveTiles.length > 3 ? 4 : veveTiles.length;

      const selectedTiles = [];

      for (let i = 0; i < toLoopFor; i++) {
        selectedTiles.push(veveTiles[i]);
      }

      for (let j = selectedTiles.length; j < veveTiles.length; j++) {
        veveTitleInStock.push(veveTiles[j]);
      }

      setSelectedVeveTiles(selectedTiles);
    }
  }, [veveTiles]);

  useEffect(() => {
    setFavourites([...transformVeveTiles(selectedVeveTiles), ...savedFavourites]);
  }, [savedFavourites, selectedVeveTiles]);

  if (veveStatus === 'pending' || veveStatus === 'idle') {
    return null;
  }

  return (
    <div className="text-white shortcut-container pointer-events-auto">
      {favourites.map((fav, idx) => {
        return (
          <Favourite key={fav.url + idx} fav={fav} swapVeveTitle={swapVeveTitle} removeFavourite={removeFavourite} />
        );
      })}
      {savedFavourites.length != 6 && <AddFavourite addNewFavourite={addNewFavourite} favourites={favourites} />}
    </div>
  );
};

export default Favourites;
