import { ReactSVG } from 'react-svg';
import { h, Fragment, Component, render } from 'preact';
import IsFavIco from '../../../images/is-fav.svg';

const IsFavIcon = ({ width = '20px', height = '20px' }) => {
  return (
    <ReactSVG
      src={IsFavIco}
      beforeInjection={(svg) => {
        svg.setAttribute('width', width);
        svg.setAttribute('height', height);
      }}
    />
  );
};

export const IsFavIconAlt = ({ width = '25px', height = '25px' }) => <img src={IsFavIco} style={{ width, height }} />;

export default IsFavIcon;
