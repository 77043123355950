import { ReactSVG } from 'react-svg';
import { h, Fragment, Component, render } from 'preact';
import Recycle from '../../../images/recycle-sign-arrow-icon.svg';

const RecycleIcon = ({ width = '20px', height = '20px' }) => {
  return (
    <ReactSVG
      src={Recycle}
      beforeInjection={(svg) => {
        svg.setAttribute('width', width);
        svg.setAttribute('height', height);
      }}
    />
  );
};

export default RecycleIcon;
