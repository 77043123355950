import { ReactSVG } from 'react-svg';
import { h, Fragment, Component, render } from 'preact';
import Clock from '../../../images/clock.svg';

const ClockIcon = ({ width = '24px', height = '24px' }) => {
  return (
    <ReactSVG
      src={Clock}
      beforeInjection={(svg) => {
        svg.setAttribute('width', width);
        svg.setAttribute('height', height);
      }}
    />
  );
};

export default ClockIcon;
