/* TEMP REMINDERS 
that will change in some interval and
needs to be re calculated
*/
import { h, Fragment, Component, render } from 'preact';
import { useContext, useMemo, useReducer } from 'preact/hooks';
import { createContext } from 'preact';
const TempRemindersContext = createContext({
  currentReminders: []
});

const UPDATE_TEMP_REMINDERS = 'update temp reminders';

function tempRemindersReducer(state, action) {
  switch (action.type) {
    case UPDATE_TEMP_REMINDERS:
      return {
        ...state,
        currentReminders: action.payload.reminders
      };
    default:
      throw new Error(`${action.type} does not exist in temp reminders reducer`);
  }
}

export function updateTempReducers(dispatch, reminders) {
  dispatch({
    type: UPDATE_TEMP_REMINDERS,
    payload: {
      reminders
    }
  });
}

export function useTempRemindersContext() {
  const context = useContext(TempRemindersContext);
  if (context === undefined) {
    throw new Error('useTempRemindersContext must be within a temp reminders context');
  }
  return context;
}

export function TempRemindersContextProvider(props) {
  const [state, dispatch] = useReducer(tempRemindersReducer, {
    currentReminders: []
  });

  const value = useMemo(() => [state, dispatch], [state, dispatch]);

  return <TempRemindersContext.Provider value={value} {...props} />;
}
