import { h, Fragment, Component, render } from 'preact';
import { useState } from 'preact/hooks';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { YOUTUBE, WIDGET_STICKY, WIDGET_CLOCK, WIDGET_WEATHER, SOUNDCLOUD } from '../../../helpers/constant';
import ClockIcon from '../Icons/ClockIcon';
import CloudsIcon from '../Icons/CloudsIcon';
import SettingIcon from '../Icons/SettingIcon';
import StickyNoteIcon from '../Icons/StickyNoteIcon';
import YoutubeIcon from '../Icons/YoutubeIcon';
import SoundcloudIcon from '../Icons/SoundcloudIcon';
import Trash from '../Trash/Trash';

const SettingsToolbar = ({
  toggleYoutube,
  toggleStickyNote,
  checkPlayerShowing,
  checkWidgetShowing,
  toggleClock,
  toggleWeather,
  toggleSoundcloud
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const togglePopover = () => setIsPopoverOpen(!isPopoverOpen);


  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['top']}
      onClickOutside={() => null}
      padding={10}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'white'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div className="flex text-white p-2 gap-2 rounded-lg items-center white-border">
            <span
              onClick={toggleYoutube}
              className={`${checkPlayerShowing(YOUTUBE) ? 'green-svg' : 'white-svg'} cursor-pointer css-is-cool`}
            >
              <div className="tooltip">
                <YoutubeIcon width="25px" height="25px"/>
                <span className="tooltiptext shawarma">Youtube</span>
              </div>
            </span>
            <span
              onClick={toggleSoundcloud}
              className={`${checkPlayerShowing(SOUNDCLOUD) ? 'green-svg' : 'white-svg'} cursor-pointer css-is-cool`}
            >
              <div className="tooltip">
                <SoundcloudIcon width="30px" height="25px" />
                <span className="tooltiptext shawarma">Soundcloud</span>
              </div>
            </span>
            <span
              onClick={toggleStickyNote}
              className={`${checkWidgetShowing(WIDGET_STICKY) ? 'green-svg' : 'white-svg'} cursor-pointer css-is-cool`}
            >
              <div className="tooltip">
                <StickyNoteIcon width="30px" height="25px" />
                <span className="tooltiptext shawarma">Sticky Notes</span>
              </div>
            </span>
            <span className={`css-is-cool`}>
                  <Trash />
            </span>
            <span
              onClick={toggleClock}
              className={`${checkWidgetShowing(WIDGET_CLOCK) ? 'green-svg' : 'white-svg'} cursor-pointer css-is-cool`}
            >
              <div className="tooltip">
                <ClockIcon width="37.5px" height="25px" />
                <span className="tooltiptext shawarma">Calendar</span>
              </div>
            </span>
            <span
              onClick={toggleWeather}
              className={`${checkWidgetShowing(WIDGET_WEATHER) ? 'green-svg' : 'white-svg'} cursor-pointer css-is-cool`}
            >
              <div className="tooltip">
                <CloudsIcon width="37.5px" height="25px" />
                <span className="tooltiptext shawarma">Weather</span>
              </div>
            </span>
          </div>
        </ArrowContainer>
      )}
    >
      <div className='settings-icon-div'>
        <SettingIcon onClick={togglePopover} className="h-10 w-10 p-1 cursor-pointer button-almostwhite radiant" />
      </div>
    </Popover>
  );
};

export default SettingsToolbar;
