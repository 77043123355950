import {
  handleCreateNewCalendarEvent,
  handleDeleteCalendarEventAction,
  handleUpdateCalendarEvent,
  useCalendarContext
} from '../context/calendar';
import { handleCreateReminderAction, handleDeleteReminderAction, useRemindersContext } from '../context/reminders';
import { COLORS } from '../../helpers/items-constant';
import {
  handleCreateNewStickyNoteAction,
  handleDeleteStickyNoteAction,
  handlePermanentlyDeleteStickyNoteAction,
  handleUpdateStickyNoteAction,
  useStickyNoteBoxContext
} from '../context/stickyNoteBox';
import { addCalendarEventToTrash, addStickyNoteToTrash, useTrashBoxContext } from '../context/trashBox';

export const useCalendar = () => {
  const [calendarValues, dispatchCalendar] = useCalendarContext();
  const [reminderVals, dispatchReminder] = useRemindersContext();
  const [stickyNoteBox, dispatchStickyNote] = useStickyNoteBoxContext();
  const [, dispatchTrashBox] = useTrashBoxContext();

  const { accessible, events } = calendarValues;

  const clearReminder = (uuid) => handleDeleteReminderAction(dispatchReminder, uuid);

  const createNewEvent = ({ note, reminder, isStickyNote, eventDate }) => {
    const event = handleCreateNewCalendarEvent(dispatchCalendar, {
      note,
      eventDate,
      isStickyNote
    });

    if (isStickyNote) {
      const noteData = { uuid: event.uuid, note: note, xPosition: 60, yPosition: 4, color: COLORS.default };
      handleCreateNewStickyNoteAction(dispatchStickyNote, noteData);
    }

    if (reminder) {
      handleCreateReminderAction(dispatchReminder, event.uuid, reminder);
    }
  };

  const updateEvent = (eventData) => {
    handleUpdateCalendarEvent(dispatchCalendar, eventData);

    const { uuid, note } = eventData;

    if (stickyNoteBox.stickyNotes[uuid] && eventData.isStickyNote) {
      handleUpdateStickyNoteAction(dispatchStickyNote, {
        ...stickyNoteBox.stickyNotes[uuid],
        note
      });
    }
    if (!stickyNoteBox.stickyNotes[uuid] && eventData.isStickyNote) {
      const noteData = {
        uuid: eventData.uuid,
        note: eventData.note,
        xPosition: 60,
        yPosition: 4,
        color: COLORS.default
      };
      handleCreateNewStickyNoteAction(dispatchStickyNote, noteData);
    }

    if (stickyNoteBox.stickyNotes[uuid] && !eventData.isStickyNote) {
      handlePermanentlyDeleteStickyNoteAction(dispatchStickyNote, uuid);
    }

    handleDeleteReminderAction(dispatchReminder, eventData.uuid);
    if (eventData.reminder) {
      handleCreateReminderAction(dispatchReminder, eventData.uuid, eventData.reminder);
    }
  };

  const deleteEvent = (uuid) => {
    handleDeleteCalendarEventAction(dispatchCalendar, uuid);
    addCalendarEventToTrash(dispatchTrashBox, uuid);

    if (stickyNoteBox.stickyNotes[uuid]) {
      handleDeleteStickyNoteAction(dispatchStickyNote, uuid);
      addStickyNoteToTrash(dispatchTrashBox, uuid);
    }
  };

  return {
    accessible,
    events,
    createNewEvent,
    deleteEvent,
    updateEvent,
    clearReminder
  };
};
