import { h, Fragment, Component, render } from 'preact';
import { ReactSVG } from 'react-svg';
import SoundcloudIco from '../../../images/soundcloud-icon.svg';

const SoundcloudIcon = ({ width = '20px', height = '20px' }) => {
  return (
    <ReactSVG
      src={SoundcloudIco}
      beforeInjection={(svg) => {
        svg.setAttribute('width', width);
        svg.setAttribute('height', height);
      }}
    />
  );
};

export default SoundcloudIcon;
