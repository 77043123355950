import Api from '../api';

class Soundcloud {
  searchTracks = async (params) => {
    return Api.get(`soundcloud/search/tracks`, null, params);
  };

  getOembed = async (params) => {
    return Api.get(`soundcloud/oembed`, null, params);
  };
}

export default new Soundcloud();
