import { h, Component, render } from "preact";
import Logo from "../../../images/bigoloo.png";
const AppLogo = () => {
  return (
    <div className="w-36 outline-hidden">
      <img src={Logo} className="w-full h-full fill-current rounded-3xl" />
    </div>
  );
};

export default AppLogo;
