import { h, Fragment, Component, render } from 'preact';
import { useContext, useEffect, useMemo, useReducer } from 'preact/hooks';
import { ITEMS } from '../../helpers/items-constant';
import { useLocalStorageState } from '../customHooks/useLocalStorageState';
import { createContext } from 'preact';

const SoundcloudContext = createContext();

export const SOUNDCLOUD_NOTHING = 'NOTHING';
export const SOUNDCLOUD_TRACK = 'TRACK';

export const soundcloudPlayType = {
  track: SOUNDCLOUD_TRACK,
  nothing: SOUNDCLOUD_NOTHING
};

const PLAY_SOUNDCLOUD_TRACK = 'play soundcloud track';
const ADD_FAVORITE_SOUNDCLOUD_TRACK = 'add favorite soundcloud track';
const REMOVE_FAVORITE_SOUNDCLOUD_TRACK = 'remove favorite soundcloud track';

function soundcloudReducer(state, action) {
  switch (action.type) {
    case PLAY_SOUNDCLOUD_TRACK:
      return {
        ...state,
        playType: soundcloudPlayType.track,
        playUrl: action.payload.url,
        thumbnail: action.payload.thumbnail,
        title: action.payload.title
      };
    case ADD_FAVORITE_SOUNDCLOUD_TRACK:
      return {
        ...state,
        favorites: state.favorites
          ? [
              ...state.favorites,
              {
                playType: action.payload.playType,
                playUrl: action.payload.playUrl,
                thumbnail: action.payload.thumbnail,
                title: action.payload.title
              }
            ]
          : [
              {
                playType: action.payload.playType,
                playUrl: action.payload.playUrl,
                thumbnail: action.payload.thumbnail,
                title: action.payload.title
              }
            ]
      };
    case REMOVE_FAVORITE_SOUNDCLOUD_TRACK:
      return {
        ...state,
        favorites: state.favorites.filter((fav) => fav.playUrl !== action.payload.playUrl)
      };
    default:
      throw new Error(`${action.type} does not exist in soundcloud reducer`);
  }
}

export function SoundcloudContextProvider(props) {
  const [soundcloudLocalStorage, setSoundcloudLocalStorage] = useLocalStorageState(ITEMS.soundcloud, {
    playType: soundcloudPlayType.nothing,
    playUrl: SOUNDCLOUD_NOTHING,
    favorites: []
  });

  const [state, dispatch] = useReducer(soundcloudReducer, soundcloudLocalStorage);

  useEffect(() => {
    setSoundcloudLocalStorage(state);
  }, [state, state?.playType, state?.playUrl]);

  const value = useMemo(() => [state, dispatch], [state, dispatch]);

  return <SoundcloudContext.Provider value={value} {...props} />;
}

export function useSoundcloudContext() {
  const context = useContext(SoundcloudContext);

  if (context === undefined) {
    throw new Error('useSoundcloudContext must be within a Soundcloud Context Provider');
  }

  return context;
}

export const playSoundcloudTrack = (dispatch, url, thumbnail, title) => {
  dispatch({
    type: PLAY_SOUNDCLOUD_TRACK,
    payload: {
      url,
      thumbnail,
      title
    }
  });
};

export const addSoundcloudFavorite = (dispatch, playUrl, playType, thumbnail, title) => {
  dispatch({
    type: ADD_FAVORITE_SOUNDCLOUD_TRACK,
    payload: {
      playUrl,
      playType,
      thumbnail,
      title
    }
  });
};

export const removeSoundcloudFavorite = (dispatch, playUrl) => {
  dispatch({
    type: REMOVE_FAVORITE_SOUNDCLOUD_TRACK,
    payload: {
      playUrl
    }
  });
};
