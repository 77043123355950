import Api from '../api';

class Weather {
  fetchWeather = async (queryParams = {}) => {
    return Api.get(`weather`, null, queryParams);
  };

  fetchCitySuggestions = async (queryParams = {}) => {
    return Api.get(`weather/suggest-city`, null, queryParams);
  };
}

export default new Weather();
