import { ReactSVG } from 'react-svg';
import { h, Fragment, Component, render } from 'preact';
import RightArrow from '../../../images/right-arrow.svg';

const RightArrowIcon = ({ width = '20px', height = '20px' }) => {
  return (
    <ReactSVG
      src={RightArrow}
      beforeInjection={(svg) => {
        svg.setAttribute('width', width);
        svg.setAttribute('height', height);
      }}
    />
  );
};

export default RightArrowIcon;
