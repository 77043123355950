import { useContext, useEffect, useMemo, useReducer } from 'preact/hooks';
import { COLORS, ITEMS } from '../../helpers/items-constant';
import { useLocalStorageState } from '../customHooks/useLocalStorageState';
import { createContext } from 'preact';
import { h, Fragment, Component, render } from 'preact';
import { v4 as uuidv4 } from 'uuid';

const StickyNoteBoxContext = createContext([]);

export const DEFAULT_STICKY_NOTE_WIDTH = 224;
export const DEFAULT_STICKY_NOTE_HEIGHT = 256;

export function createStickyNote({
  note,
  xPosition,
  yPosition,
  color = COLORS.default,
  uuid,
  width = DEFAULT_STICKY_NOTE_WIDTH,
  height = DEFAULT_STICKY_NOTE_HEIGHT
}) {
  return {
    uuid: uuid ? uuid : uuidv4(),
    note,
    xPosition,
    yPosition,
    rotate: 0,
    createdOn: new Date().toUTCString(),
    color,
    width,
    height,
    updatedOn: new Date()
  };
}

export function updateStickyNotes(
  stickyNotes,
  { uuid, note, xPosition, yPosition, rotate, createdOn, color = COLORS.default, width, height }
) {
  const newStickyNotes = { ...stickyNotes };
  newStickyNotes[uuid] = {
    ...newStickyNotes.uuid,
    rotate,
    uuid,
    note,
    color,
    xPosition,
    yPosition,
    width: width ? width : newStickyNotes[uuid]?.width || DEFAULT_STICKY_NOTE_WIDTH,
    height: height ? height : newStickyNotes[uuid]?.height || DEFAULT_STICKY_NOTE_HEIGHT,
    updatedOn: new Date().toUTCString(),
    createdOn
  };

  return newStickyNotes;
}

export function removeStickyNotePermanently(stickyNotes, uuid) {
  const newStickyNotes = { ...stickyNotes };
  if (newStickyNotes[uuid]) {
    delete newStickyNotes[uuid];
  }
  return newStickyNotes;
}

export function addStickyNote(stickyNotes, newNote) {
  const newNotes = {
    ...stickyNotes,
    [newNote.uuid]: newNote
  };
  return newNotes;
}

const CREATE_NEW_STICKY_NOTE = 'create new sticky note';
const UPDATE_STICKY_NOTE = 'update sticky note';
const DELETE_STICKY_NOTE = 'delete sticky note';
const PERMANENTLY_DELETE_STICKY_NOTE = 'premanently delete sticky note';
const RESTORE_STICKY_NOTE = 'restore sticky note';

/*
    Sticky Note State

[
    accessible: [uuid1, uuid2],
    stickyNotes : {
        uuid1: {
            ....
        },
        uuid2:{
            ....
        }
    }
]

*/

function stickyNoteBoxReducer(state, action) {
  switch (action.type) {
    case CREATE_NEW_STICKY_NOTE:
      return {
        ...state,
        accessible: [...state.accessible, action.payload.stickyNote.uuid],
        stickyNotes: {
          ...state.stickyNotes,
          [action.payload.stickyNote.uuid]: {
            ...action.payload.stickyNote
          }
        }
      };
    case UPDATE_STICKY_NOTE:
      return {
        ...state,
        accessible: [...state.accessible],
        stickyNotes: {
          ...updateStickyNotes(state.stickyNotes, action.payload.updatedNoteData)
        }
      };
    case DELETE_STICKY_NOTE:
      return {
        ...state,
        accessible: state.accessible.filter((uuid) => uuid !== action.payload.uuid)
      };
    case RESTORE_STICKY_NOTE:
      return {
        ...state,
        accessible: [...state.accessible, action.payload.uuid]
      };
    case PERMANENTLY_DELETE_STICKY_NOTE:
      return {
        ...state,
        accessible: state.accessible.filter((uuid) => uuid !== action.payload.uuid),
        stickyNotes: {
          ...removeStickyNotePermanently(state.stickyNotes, action.payload.uuid)
        }
      };
    default:
      throw new Error(`${action.type} does not exist in sticky note box reducer`);
  }
}

export function handleCreateNewStickyNoteAction(dispatch, stickyNote) {
  const newStickyNote = createStickyNote(stickyNote);

  dispatch({
    type: CREATE_NEW_STICKY_NOTE,
    payload: {
      stickyNote: {
        ...newStickyNote
      }
    }
  });
}

export function handleUpdateStickyNoteAction(dispatch, updatedNoteData) {
  dispatch({
    type: UPDATE_STICKY_NOTE,
    payload: {
      updatedNoteData
    }
  });
}

export function handleDeleteStickyNoteAction(dispatch, uuid) {
  dispatch({
    type: DELETE_STICKY_NOTE,
    payload: {
      uuid
    }
  });
}

export function handlePermanentlyDeleteStickyNoteAction(dispatch, uuid) {
  dispatch({
    type: PERMANENTLY_DELETE_STICKY_NOTE,
    payload: {
      uuid
    }
  });
}

export function handleRestoreStickyNoteAction(dispatch, uuid) {
  dispatch({
    type: RESTORE_STICKY_NOTE,
    payload: {
      uuid
    }
  });
}

export function StickyNoteBoxContextProvider(props) {
  const [stickyNoteBoxLocalStorage, setStickyNoteBoxLocalStorage] = useLocalStorageState(ITEMS.stickyNotes, {
    accessible: [],
    stickyNotes: {}
  });

  const [state, dispatch] = useReducer(stickyNoteBoxReducer, stickyNoteBoxLocalStorage);
  useEffect(() => {
    setStickyNoteBoxLocalStorage(state);
  }, [state, state?.stickyNotes, state?.accessible]);

  const value = useMemo(() => [state, dispatch], [state, dispatch]);

  return <StickyNoteBoxContext.Provider value={value} {...props} />;
}

export function useStickyNoteBoxContext() {
  const context = useContext(StickyNoteBoxContext);
  if (context === undefined) {
    throw new Error('useStickyNoteBoxContext must be within a Sticky Note Box Context Provider');
  }

  return context;
}
