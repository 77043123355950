import { h, Fragment, Component, render } from 'preact';
import FullTrashIcon from '../Icons/FullTrashIcon';
import { useLocalStorageState } from '../../customHooks/useLocalStorageState';
import { useStickyNotes } from '../../customHooks/useStickyNotes';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { useResizeDetector } from 'react-resize-detector';
import EditIcon from '../Icons/EditIcon';
import { removeFromLocalStorage } from '../../../utils/localstorage';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import ClockRightIcon from '../Icons/ClockRightIcon';
import ReactDatePicker from 'react-datepicker';
import { filterTime } from '../../../utils/reminder';
import './sticky.css';

import { handleCreateReminderAction, handleDeleteReminderAction, useRemindersContext } from '../../context/reminders';
import { COLORS, ITEMS } from '../../../helpers/items-constant';
import ColorsIcon from '../Icons/ColorsIcon';
import TickIcon from '../Icons/TickIcon';
import RotateLeftIcon from '../Icons/RotateLeftArrow';
import { useTempRemindersContext } from '../../context/currentReminders';
import { handleCreateNewCalendarEvent, handleUpdateCalendarEvent, useCalendarContext } from '../../context/calendar';
import { DEFAULT_STICKY_NOTE_WIDTH, DEFAULT_STICKY_NOTE_HEIGHT } from '../../context/stickyNoteBox';

const FORM_TYPE = {
  add: 'add',
  edit: 'edit'
};

const StickyNoteViewDatePicker = ({ stickyNote, closePopover, remindersDispatch, remindersState }) => {
  const stickyNoteReminderDate = remindersState?.remindersVal?.[stickyNote.uuid] ?? null;
  const [selectedReminder, setSelectedReminder] = useState(stickyNoteReminderDate);
  const [calendarValues, calendarDispatch] = useCalendarContext();

  const saveReminder = () => {
    handleDeleteReminderAction(remindersDispatch, stickyNote.uuid);

    if (selectedReminder) {
      handleCreateReminderAction(remindersDispatch, stickyNote.uuid, new Date(selectedReminder));

      if (calendarValues.events[stickyNote.uuid]) {
        handleUpdateCalendarEvent(calendarDispatch, {
          ...calendarValues.events[stickyNote.uuid],
          reminder: new Date(selectedReminder)
        });
      } else {
        handleCreateNewCalendarEvent(calendarDispatch, {
          uuid: stickyNote.uuid,
          note: stickyNote.note,
          isStickyNote: true,
          eventDate: new Date(selectedReminder)
        });
      }
    }

    closePopover();
  };

  const clearReminder = () => setSelectedReminder(null);

  return (
    <div className="main-background rounded-lg p-2 flow-content" style={{ minWidth: '300px' }}>
      <p className="max-w-full">
        <h4>Set a reminder: </h4>
      </p>
      <div onClick={clearReminder} className="black-svg cursor-pointer" style={{ maxWidth: '32px' }}>
        <FullTrashIcon />
      </div>
      <ReactDatePicker
        className="border-gray-400 border-2 mx-auto block w-full rounded-lg text-black"
        selected={selectedReminder ? new Date(selectedReminder) : null}
        showTimeSelect
        onChange={(date) => setSelectedReminder(date)}
        dateFormat="MMMM d, yyyy h:mm aa"
        minDate={new Date()}
        placeholderText="Pick reminder date and time"
        timeIntervals={2}
        filterTime={filterTime}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />

      <div className="flex gap-2 ">
        <button onClick={saveReminder} className="text-blue-500">
          Save
        </button>
        <button onClick={closePopover} className="text-red-500">
          Cancel
        </button>
      </div>
    </div>
  );
};

const hasReminder = (uuid, reminders) => (reminders?.remindersVal?.[uuid] ? true : false);
const needsToBeReminded = (uuid, reminders) => new Date() > new Date(reminders?.remindersVal?.[uuid]);

const StickyNoteView = ({ stickyNote, changeToEdit, handleDelete }) => {
  const [{ currentReminders }] = useTempRemindersContext();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const closePopover = () => setIsPopoverOpen(false);
  const [remindersState, remindersDispatch] = useRemindersContext();
  const stickyHasReminder = hasReminder(stickyNote.uuid, remindersState);
  let needsReminder = stickyHasReminder
    ? currentReminders.includes(stickyNote.uuid) || needsToBeReminded(stickyNote.uuid, remindersState)
    : false;

  /*
   * Determining the position of the reminder picker based on the current position
   * of the sticky note.
   */
  const dateViewOpeningSide = stickyNote.xPosition > 352 ? 'left' : 'right';

  const toggleIsPopoverOpen = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  return (
    <div>
      <div className="flex gap-2 absolute right-2 top-2 ">
        <div>
          <Popover
            isOpen={isPopoverOpen}
            positions={[dateViewOpeningSide]}
            padding={10}
            content={({ position, childRect, popoverRect }) => (
              <ArrowContainer
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                onClickOutside={() => null}
                arrowColor={'white'}
                arrowSize={10}
                arrowStyle={{ opacity: 1 }}
                className="popover-arrow-container"
                arrowClassName="popover-arrow"
              >
                <StickyNoteViewDatePicker
                  remindersState={remindersState}
                  remindersDispatch={remindersDispatch}
                  stickyNote={stickyNote}
                  closePopover={closePopover}
                />
              </ArrowContainer>
            )}
          >
            <div
              onClick={toggleIsPopoverOpen}
              onDoubleClick={(e) => e.stopPropagation()}
              className={`${stickyHasReminder ? 'has-reminder-svg' : 'white-svg'} ${
                needsReminder ? 'has-current-reminder' : ''
              } cursor-pointer`}
            >
              {stickyHasReminder && <div></div>}
              <div className={`${!isPopoverOpen && 'tooltip'}`}>
                <ClockRightIcon />
                {!isPopoverOpen && (
                  <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-135px' }}>
                    Set a Reminder for <br /> this sticky note
                  </span>
                )}
              </div>
            </div>
          </Popover>
        </div>
        <div className="tooltip top-0">
          <div onClick={changeToEdit} className="w-4 h-4 transform transition-transform hover:scale-125 white-svg">
            <EditIcon className="w-4 h-4 absolute right-2 transform transition-transform hover:scale-125" />
          </div>
          <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-75px' }}>
            Edit note
          </span>
        </div>
        <div className="tooltip top-0">
          <div onClick={handleDelete} className="w-4 h-4 transform white-svg transition-transform hover:scale-125">
            <FullTrashIcon />
          </div>
          <span className="tooltiptext tooltiptext--sn-icons" style={{ left: '-95px' }}>
            Move to the <br /> trash bin
          </span>
        </div>
      </div>
      <pre className="h-full w-full whitespace-pre-wrap overflow-y-auto sticky-area p-2">{stickyNote.note}</pre>
    </div>
  );
};

const StickyNoteForm = ({
  stickyNoteKey = 'inputNote',
  stickyNoteColor = COLORS.default,
  createNewStickyNote,
  defaultNoteValue = '',
  formType,
  handleUpdate = () => null,
  changeBackToPreview = () => null
}) => {
  const [noteValue, setNoteValue] = useLocalStorageState(stickyNoteKey, defaultNoteValue);

  const textAreaRef = useRef();

  const IS_CREATE_FORM = formType === FORM_TYPE.add;

  const addNote = (e) => {
    e.preventDefault();
    if (!noteValue) {
      return;
    }
    createNewStickyNote({ note: noteValue, xPosition: 250, yPosition: 4, color: stickyNoteColor });
    setNoteValue('');
  };

  const goBack = () => {
    removeFromLocalStorage(stickyNoteKey);
    changeBackToPreview();
  };

  useEffect(() => {
    /*
     * When the user double clicks, and makes the sticky note editable
     * we want the focus to be on the text area.
     */
    if (!IS_CREATE_FORM) {
      textAreaRef.current.focus();
    }
  }, [IS_CREATE_FORM]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (IS_CREATE_FORM) {
      addNote(e);
    } else {
      handleUpdate(noteValue);
    }
  };

  return (
    <form onSubmit={handleFormSubmit} className="h-full w-full">
      <textarea
        value={noteValue}
        style={{ marginTop: '5px' }}
        class={`h-full w-full outline-none   sticky-area ${stickyNoteColor} resize-none text-xl`}
        onChange={(event) => setNoteValue(event.target.value)}
        ref={textAreaRef}
        placeholder={`${IS_CREATE_FORM ? 'Create a new Sticky note...' : 'Edit the form'}`}
      ></textarea>
      {!IS_CREATE_FORM && (
        <button
          className={`
            absolute
            top-0
            right-10
            w-6
            bg-none
            p-1 
            text-primary-text
            hover:text-secondary-one
            ${IS_CREATE_FORM && 'hover:rotate-180'}
            hover:rounded-sm
            hover:scale-125
            text-3xl
            border-none 
            outline-none
            transform
            transition-all 
            white-svg
        `}
          onClick={goBack}
        >
          <RotateLeftIcon />
        </button>
      )}

      <button
        className={`
            absolute
            top-0
            right-2
            w-6
            bg-none
            p-1 
            text-primary-text
            hover:text-secondary-one
            ${IS_CREATE_FORM && 'hover:rotate-180'}
            hover:rounded-sm
            hover:scale-125
            text-3xl
            border-none 
            outline-none
            transform
            transition-all 
            white-svg
        `}
      >
        {IS_CREATE_FORM ? '+' : <TickIcon />}
      </button>
    </form>
  );
};

const stickyNoteView = {
  preview: 'preview',
  edit: 'edit'
};

const StickyNote = ({ stickyNote, updateStickyNote, deleteStickyNote }) => {
  /** Mouse Position Relative to Sticky Note */
  const [mousePosition, setMousePosition] = useState([0, 0]);

  const [currentlyHovering, setCurrentlyHovering] = useState(false);

  const onResize = (width, height) => {
    if (currentlyHovering) {
      updateStickyNote({
        ...stickyNote,
        height: height + 40,
        width
      });
    }
  };

  const { ref: stickyNoteRef } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 300,
    skipOnMount: true,
    onResize
  });

  const calculateRelativeMousePos = (e) => {
    let rect = stickyNoteRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setMousePosition([x, y]);
  };

  const noteViewName = stickyNote.uuid + '-view';
  const [view, setView] = useLocalStorageState(noteViewName, () => stickyNoteView.preview);
  const stickyNoteKey = `inputNote-${stickyNote.uuid}`;

  const changeToEdit = () => {
    setView(stickyNoteView.edit);
  };

  const changeBackToPreview = () => setView(stickyNoteView.preview);

  const handleUpdate = (noteValue) => {
    updateStickyNote({
      ...stickyNote,
      note: noteValue
    });
    removeFromLocalStorage(stickyNoteKey);
    setView(stickyNoteView.preview);
  };

  const handleDelete = () => {
    removeFromLocalStorage(noteViewName);
    deleteStickyNote(stickyNote.uuid);
  };

  const dropNote = (event) => {
    let newXPosition = event.pageX - mousePosition[0];
    let newYPosition = event.pageY - mousePosition[1];
    let documentWidth = document.documentElement.clientWidth;
    let documentHeight = document.documentElement.clientHeight;

    /* Checking and Adjusting if the sticky note
    tries to go outside windows bound
    */
    if (newXPosition < 0) {
      newXPosition = 0;
    }
    if (newYPosition < 0) {
      newYPosition = 0;
    }

    if (newXPosition + stickyNote.width > documentWidth) {
      newXPosition = documentWidth - stickyNote.width;
    }
    if (newYPosition + stickyNote.height > documentHeight) {
      newYPosition = documentHeight - stickyNote.height;
    }

    updateStickyNote({
      ...stickyNote,
      xPosition: newXPosition,
      yPosition: newYPosition
    });
  };

  const changeStickyNoteColor = (color) => {
    updateStickyNote({
      ...stickyNote,
      color
    });
  };

  useEffect(() => {
    const note = stickyNoteRef.current;
    note.style.left = stickyNote.xPosition + 'px';
    note.style.top = stickyNote.yPosition + 'px';
  }, [stickyNote.xPosition, stickyNote.yPosition]);

  const handleDoubleClickOnNote = () => {
    if (view === stickyNoteView.preview) {
      changeToEdit();
    }
  };

  return (
    <div
      onDoubleClick={handleDoubleClickOnNote}
      className={`absolute
          cursor-pointer ${stickyNote.color}  block
          pb-4
          pt-6
          left-60
          top-4
          text-xl
          shadow-sm transition-transform
          font-sans
          rounded-lg
          `}
      style={{
        transform: `rotate(${stickyNote.rotate}deg)`,
        resize: 'both',
        overflow: 'auto',
        width: `${stickyNote.width ?? DEFAULT_STICKY_NOTE_WIDTH}px`,
        height: `${stickyNote.height ?? DEFAULT_STICKY_NOTE_HEIGHT}px`,
        maxWidth: '500px',
        maxHeight: '500px',
        minHeight: '90px',
        minWidth: '115px'
      }}
      draggable="true"
      onDragStart={calculateRelativeMousePos}
      onDragEnd={dropNote}
      onMouseEnter={() => setCurrentlyHovering(true)}
      onMouseLeave={() => setCurrentlyHovering(false)}
      ref={stickyNoteRef}
    >
      <div className="absolute top-1">
        <NoteColorChanger changeStickyNoteColor={changeStickyNoteColor} />
      </div>
      {view === stickyNoteView.edit && (
        <StickyNoteForm
          defaultNoteValue={stickyNote.note}
          formType={FORM_TYPE.edit}
          stickyNoteKey={`inputNote-${stickyNote.uuid}`}
          handleUpdate={handleUpdate}
          stickyNoteColor={stickyNote.color}
          changeBackToPreview={changeBackToPreview}
        />
      )}
      {view === stickyNoteView.preview && (
        <StickyNoteView
          stickyNote={stickyNote}
          changeToEdit={changeToEdit}
          stickyNoteKey={stickyNoteKey}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

const NoteColorChanger = ({ changeStickyNoteColor }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const colors = Object.keys(COLORS);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['right']}
      padding={10}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'white'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div className="flex border-2" style={{ borderColor: 'white' }}>
            {colors.map((color) => (
              <div
                key={COLORS[color]}
                onClick={() => {
                  changeStickyNoteColor(COLORS[color]);
                  setIsPopoverOpen(false);
                }}
                className={`w-6 h-6 cursor-pointer ${COLORS[color]}`}
              ></div>
            ))}
          </div>
        </ArrowContainer>
      )}
    >
      <div className={`${!isPopoverOpen && 'tooltip'} absolute top-1 left-1`}>
        <button
          className="w-4 h-4"
          onDoubleClick={(e) => e.stopPropagation()}
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <ColorsIcon />
        </button>
        {!isPopoverOpen && <span className="tooltiptext tooltiptext--color-change">Change color</span>}
      </div>
    </Popover>
  );
};

const Sticky = () => {
  const { accessibleStickyNotes, createNewStickyNote, updateStickyNote, stickyNoteDataFetcher, deleteStickyNote } =
    useStickyNotes();

  const [stickyNoteCreationColor, setStickyNoteCreationColor] = useLocalStorageState(
    ITEMS.stickyNotes + 'creationColor',
    COLORS.default
  );

  const changeStickyNoteColor = (color) => setStickyNoteCreationColor(color);

  return (
    <Fragment>
      <div
        className={`className="flex
            relative 
            flex-col 
            ${stickyNoteCreationColor}
            p-4
            pt-6
            shadow-sm transition-transform
            font-sans
            w-56
            h-64
            text-xl
            -rotate-3
            rounded-lg
          `}
      >
        <div className="absolute top-1">
          <NoteColorChanger changeStickyNoteColor={changeStickyNoteColor} />
        </div>

        <StickyNoteForm
          createNewStickyNote={createNewStickyNote}
          formType={FORM_TYPE.add}
          stickyNoteKey={'inputnote'}
          stickyNoteColor={stickyNoteCreationColor}
        />
      </div>

      {accessibleStickyNotes.map((uuid) => (
        <StickyNote
          stickyNote={stickyNoteDataFetcher(uuid)}
          key={uuid}
          updateStickyNote={updateStickyNote}
          deleteStickyNote={deleteStickyNote}
        />
      ))}
    </Fragment>
  );
};

export default Sticky;
