import { ReactSVG } from 'react-svg';
import { h, Fragment, Component, render } from 'preact';
import ClockRight from '../../../images/clock-rotate-right-icon.svg';

const ClockRightIcon = ({ width = '20px', height = '20px' }) => {
  return (
    <ReactSVG
      src={ClockRight}
      beforeInjection={(svg) => {
        svg.setAttribute('width', width);
        svg.setAttribute('height', height);
      }}
    />
  );
};

export default ClockRightIcon;
